<template>
  <div class="invite-container">
    <p class="invite-text">Invite Friends To Earn Bonus Rewards!</p>
    <div class="invite-code-container">
      <el-input
        v-model="inviteCodeDisplay"
        readonly
        class="invite-code-input"
      ></el-input>
      <div class="invite-actions">
        <el-button icon="DocumentCopy" @click="copyCode" class="action-button"></el-button>
        <el-button icon="Share" @click="shareCode" class="action-button"></el-button>
        <el-button @click="goToQrCode" class="action-button">
          <template #default>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="18"
              width="18"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="size-6"
            >
              <path
                fill-rule="evenodd"
                d="M3 4.875C3 3.839 3.84 3 4.875 3h4.5c1.036 0 1.875.84 1.875 1.875v4.5c0 1.036-.84 1.875-1.875 1.875h-4.5A1.875 1.875 0 0 1 3 9.375v-4.5ZM4.875 4.5a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5Zm7.875.375c0-1.036.84-1.875 1.875-1.875h4.5C20.16 3 21 3.84 21 4.875v4.5c0 1.036-.84 1.875-1.875 1.875h-4.5a1.875 1.875 0 0 1-1.875-1.875v-4.5Zm1.875-.375a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75A.75.75 0 0 1 6 7.5v-.75Zm9.75 0A.75.75 0 0 1 16.5 6h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75ZM3 14.625c0-1.036.84-1.875 1.875-1.875h4.5c1.036 0 1.875.84 1.875 1.875v4.5c0 1.035-.84 1.875-1.875 1.875h-4.5A1.875 1.875 0 0 1 3 19.125v-4.5Zm1.875-.375a.375.375 0 0 0-.375.375v4.5c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375v-4.5a.375.375 0 0 0-.375-.375h-4.5Zm7.875-.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Zm6 0a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75h-.75a.75.75 0 0 1-.75-.75v-.75Z"
                clip-rule="evenodd"
              />
            </svg>
          </template>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      inviteCode: '', // 初始为空字符串
      fullInviteLink: '', // 完整的邀请链接（来自后端）
    };
  },
  computed: {
    ...mapGetters('profile', ['userProfile']),
    inviteCodeDisplay() {
      return this.inviteCode ? `/register?referral_code=${this.inviteCode}` : '';  // 显示简化的链接
    },
  },
  watch: {
    // 监听userProfile的变化
    userProfile: {
      handler(newVal) {
        if (newVal && newVal.referral_code && newVal.referral_link) {
          this.inviteCode = newVal.referral_code;  // 设置邀请码
          this.fullInviteLink = newVal.referral_link;  // 设置后端提供的完整链接
        }
      },
      immediate: true, // 在绑定后立即执行handler
    },
  },
  mounted() {
    this.$store.dispatch('profile/fetchUserProfile');
  },
  methods: {
    copyCode() {
      if (this.fullInviteLink) {
        navigator.clipboard.writeText(this.fullInviteLink).then(() => {
          this.$message.success('Invite link copied to clipboard!');
        }).catch(error => {
          console.error('Failed to copy invite link:', error);
          this.$message.error('Failed to copy invite link');
        });
      } else {
        this.$message.error('Invite link is not available');
      }
    },
    shareCode() {
      if (navigator.share && this.fullInviteLink) {
        navigator
          .share({
            title: 'Invite Code',
            text: `Register using this link: ${this.fullInviteLink}`,
            url: this.fullInviteLink,
          })
          .then(() => this.$message.success('Invite link shared successfully!'))
          .catch(error => {
            console.error('Error sharing invite link:', error);
            this.$message.error('Error sharing invite link');
          });
      } else {
        this.$message.error('Web Share API not supported or invite link not available.');
      }
    },
    goToQrCode() {
      this.$router.push({ name: 'QrCode' });
    },
  },
};
</script>

<style scoped>
.invite-container {
  background: linear-gradient(135deg, #f8f7ee, #f1f0d4);
  border-radius: 12px;
  padding: 10px;
  text-align: center;
  margin: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.invite-text {
  margin-bottom: 10px;
  color: #535353;
  font-weight: bold;
  font-size: 14px;
}

.invite-code-container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 5px;
}

.invite-actions {
  display: flex;
  align-items: center;
}

.invite-code-input{
  font-size: 12px;
}

.action-button {
  margin-left: 5px;
  background-color: #ffffff;
  color: #007bff;
  border-radius: 8px;
  padding: 8px;
  font-size: 16px;
}

.action-button:hover {
  background-color: #cbe0fd;
}
</style>

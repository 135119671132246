import axios from '@/axios';
import router from '@/router';

const state = {
  token: localStorage.getItem('token') || '',
  refreshToken: localStorage.getItem('refresh_token') || '',
  uid: localStorage.getItem('uid') || '',
  tokenExpirationDate: localStorage.getItem('token_expiration_date') || null,
  isTokenRefreshing: false,
  hasShownExpiryAlert: false,
  isLoggingIn: false,
  username: 'Guest',  // 默认用户名为 'Guest'
  chipsBalance: 0,  // 默认余额为 0
  remainingGrabs: 10,
  remainingQuota: 1000, 
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    localStorage.setItem('token', token);
  },
  setUsername(state, username) {
    state.username = username;
  },
  setChipsBalance(state, balance) {
    state.chipsBalance = balance;
  },
  setRemainingGrabs(state, grabs) {
    state.remainingGrabs = grabs;
  },
  setRemainingQuota(state, quota) {
    state.remainingQuota = quota;
  },
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
    localStorage.setItem('refresh_token', refreshToken);
  },
  setUid(state, uid) {
    state.uid = uid;
    localStorage.setItem('uid', uid);
  },
  setTokenExpirationDate(state, expirationDate) {
    state.tokenExpirationDate = expirationDate;
    localStorage.setItem('token_expiration_date', expirationDate);
  },
  clearAuthData(state) {
    // 清除所有与用户相关的数据
    state.token = '';
    state.refreshToken = '';
    state.uid = '';
    state.tokenExpirationDate = null;
    state.isLoggingIn = false;
    state.isTokenRefreshing = false;
    state.username = 'Guest';  // 恢复为默认值
    state.chipsBalance = 0;    // 恢复为默认值
    state.remainingGrabs = 10; // 恢复为默认值
    state.remainingQuota = 1000; // 恢复为默认值

    // 清空 localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('uid');
    localStorage.removeItem('token_expiration_date');

    // 清除 sessionStorage 或其他缓存
    sessionStorage.clear();
  },
  setTokenRefreshing(state, status) {
    state.isTokenRefreshing = status;
  },
  setHasShownExpiryAlert(state, status) {
    state.hasShownExpiryAlert = status;
  },
  setLoggingIn(state, status) {
    state.isLoggingIn = status;
  }
};

const actions = {
  async login({ commit, dispatch }, authData) {
    commit('setLoggingIn', true);
    try {
      const response = await axios.post('/api/login/', authData);
      const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
      commit('setToken', response.data.access);
      commit('setRefreshToken', response.data.refresh);
      commit('setUid', response.data.uid);
      commit('setTokenExpirationDate', expirationDate);
      commit('setHasShownExpiryAlert', false);

      // 获取用户信息和 remaining_grabs
      await dispatch('fetchUsername');
      await dispatch('fetchRemainingGrabs');
      commit('setLoggingIn', false);
      router.push({ name: 'Home' });
      return response;
    } catch (error) {
      commit('setLoggingIn', false);
      console.error('登录失败，请检查您的凭据。');
      throw error;
    }
  },
  async logout({ commit }) {
    commit('clearAuthData');
    router.push('/login');
    console.log('您已成功退出登录。');
  },
  async refreshToken({ commit, state }) {
    if (state.isTokenRefreshing || state.isLoggingIn) {
      return Promise.resolve();
    }
    commit('setTokenRefreshing', true);
    try {
      const response = await axios.post('/api/token/refresh/', {
        refresh: state.refreshToken
      });
      const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);
      commit('setToken', response.data.access);
      commit('setTokenExpirationDate', expirationDate);
      commit('setTokenRefreshing', false);
      commit('setHasShownExpiryAlert', false);
      console.log('Token 刷新成功！');
      return response;
    } catch (error) {
      commit('clearAuthData');
      if (!state.hasShownExpiryAlert) {
        console.error('您的会话已过期，请重新登录。');
        commit('setHasShownExpiryAlert', true);
      }
      throw error;
    }
  },
  async fetchUsername({ commit, state }) {
    if (!state.token) return;
    try {
      const response = await axios.get('v1/profile/');
      commit('setUsername', response.data.username);
      commit('setChipsBalance', response.data.chips_balance);
    } catch (error) {
      console.error('获取用户名失败:', error);
    }
  },
  async fetchRemainingGrabs({ commit, state }) {
    if (!state.token) return;
    try {
      const response = await axios.get('/api/redpacket/remaining_grabs/');
      commit('setRemainingGrabs', response.data.remaining_grabs);
      commit('setRemainingQuota', response.data.remaining_quota);
    } catch (error) {
      console.error('获取剩余抢包次数失败:', error);
    }
  }
};

const getters = {
  isAuthenticated(state) {
    return !!state.token;
  },
  getUsername(state) {
    return state.username;
  },
  getChipsBalance(state) {
    return state.chipsBalance;
  },
  getRemainingGrabs(state) {
    return state.remainingGrabs;
  },
  getRemainingQuota(state) {
    return state.remainingQuota;
  },
  uid(state) {
    return state.uid;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

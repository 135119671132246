import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'; // 确保导入 Vuex store
import Home from '../views/Home/AppHome.vue';
import Promotions from '../views/Promotions/AppPromo.vue';
import PromotionDetail from '@/views/Promotions/components/PromotionDetail.vue';
import Login from '../views/AppLogin.vue';
import Register from '../views/AppRegister.vue';
import RedPacket from '@/views/RedPacket.vue';
import Account from '@/views/Profile/UserAccount.vue';
import Profile from '@/views/Profile/Components/UserProfile.vue';
import ChangePassword from '@/views/Profile/Components/ChangePassword.vue';
import BankAccount from '@/views/Profile/Components/UserBank.vue';
import AddBank from '@/views/Profile/Components/AddBank.vue';
import UserNotification from '@/views/Profile/UserNotification.vue';
import UserHistory from '@/views/Reward/UserHistory.vue';
import TransferBonus from '@/views/Reward/TransferBonus.vue';
import UserReferral from '@/views/Referral/UserReferral.vue';
import QrCodePage from '@/views/Referral/UserQRCode.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/bank-account',
    name: 'BankAccount',
    component: BankAccount,
  },
  {
    path: '/add-bank',
    name: 'AddBank',
    component: AddBank,
  },
  {
    path: '/notifications',
    name: 'Notification',
    component: UserNotification,
  },
  {
    path: '/promotion',
    name: 'Promotions',
    component: Promotions,
  },
  {
    path: '/promotions/:id',
    name: 'PromotionDetail',
    component: PromotionDetail,
    props: true
  },
  {
    path: '/redpacket',
    name: 'RedPacket',
    component: RedPacket,
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: TransferBonus,
  },
  {
    path: '/history',
    name: 'History',
    component: UserHistory,
  },
  {
    path: '/referral',
    name: 'Referrals',
    component: UserReferral,
  },
  {
    path: '/QRcode',
    name: 'QrCode',
    component: QrCodePage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const publicPages = ['Home', 'Login', 'Register', 'Promotions', 'PromotionDetail'];
  const authRequired = !publicPages.includes(to.name); // 如果目标页面不在公共页面列表中，则需要身份验证

  if (authRequired && !store.getters['auth/isAuthenticated']) {
    // 用户未登录，且目标页面需要身份验证
    if (to.name !== 'Login') {
      // 如果目标页面不是 Login 页面，才进行登出并跳转
      store.dispatch('auth/logout').then(() => {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        });
      });
    } else {
      // 如果已经在 Login 页面，直接跳转
      next();
    }
  } else {
    next();
  }
});

export default router;

<template>
    <div>
        <ChipBalance />
<SearchComponent />
</div>
</template>

<script>
import ChipBalance from '@/components/ChipBalance.vue'
import SearchComponent from '@/views/Reward/components/SearchComponent.vue'; // 根据你的路径调整


export default {
  components: {
    ChipBalance,
    SearchComponent
   },
};
</script>
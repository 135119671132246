<template>
  <div class="top-games">
    <h2>Top Games</h2>
    <swiper
      :slides-per-view="2.5"
      :space-between="-15"
      class="game-slider"
    >
      <swiper-slide v-for="(game, index) in games" :key="index">
        <div class="game-card">
          <img :src="game.image" alt="game" class="game-image" />
          <div class="game-content">
            <span v-if="game.best" class="best-badge">Best</span>
            <div class="title-container">
              <h3 class="game-title">{{ game.title }}</h3>
              <div class="info-icon">i</div>
            </div>
            <div class="rtp-container">
              <span class="rtp-label">RTP</span>
              <span class="rtp-value">{{ game.rtp }}</span>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      games: [
        {
          image: require('@/assets/sMahPhe.png'),
          title: "Pac-Man's Power",
          rtp: '96.95',
          best: true
        },
        {
          image: require('@/assets/sAztecGolT.png'),
          title: "Cleopatra's Jewels",
          rtp: '96.97',
          best: true
        },
        {
          image: require('@/assets/sCleopaFor.png'),
          title: 'Mahjong Phoenix',
          rtp: '96.94',
          best: false
        },
        {
          image: require('@/assets/sAztecGolT.png'),
          title: "Cleopatra's Jewels",
          rtp: '96.97',
          best: true
        },
      ],
    };
  },
};
</script>

<style scoped>
.top-games {
  padding: 5px 10px;  /* 减少顶部和底部的内边距 */
  color: #333;
}

.game-slider {
  margin-top: 5px;  /* 减少顶部间距 */
}

.game-card {
  position: relative;
  border-radius: 15px;  /* 调整圆角 */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;  /* 增加卡片宽度 */
}

.game-image {
  width: 100%;
  object-fit: cover;
}

.game-content {
  padding: 5px;  /* 减少内边距 */
  text-align: center;
}

.best-badge {
  position: absolute;
  top: 3px;
  left: 10px;
  background-color: #ff3b30;
  color: #fff;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: bold;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.game-title {
  font-size: 12px;  /* 减小标题字体大小 */
  font-weight: bold;
  margin: 5px 0 2px;  /* 减少上下的间距 */
  white-space: nowrap;  /* 禁止换行 */
  overflow: hidden;
  text-overflow: ellipsis;  /* 超出部分用省略号显示 */
  max-width: 90px;  /* 设置最大宽度，适应不同长度的标题 */
}

.info-icon {
  font-size: 10px;  /* 设置i的大小 */
  margin-left: 5px;
  color: #fff;
  background-color: #cccccc;  /* 设置圆形背景颜色 */
  border-radius: 50%;  /* 圆形背景 */
  width: 16px;  /* 设置圆形背景的宽度 */
  height: 16px;  /* 设置圆形背景的高度 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.rtp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007aff;
  padding: 2px 10px;  /* 减少RTP容器的内边距 */
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 10px;  /* 减小字体大小 */
}

.rtp-label {
  margin-right: 2px;  /* 减少标签和值之间的间距 */
}

.rtp-value {
  font-size: 12px;  /* 调整RTP值字体大小 */
}
</style>

<template>
  <div class="rewards-card">
    <div class="rewards-header">
      总奖励收入
    </div>
    <div class="rewards-content">
      <div class="rewards-amount">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="#ffc107" class="coin-icon">
          <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z" />
          <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z" clip-rule="evenodd" />
        </svg>
        {{ totalRewards }}
      </div>
    </div>
    <div class="rewards-footer">
      待领取奖品: <span class="claimed-amount">{{ pendingRewards }}</span>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';

export default {
data() {
  return {
    totalRewards: 0.00,  // 用于存储总奖励收入
    pendingRewards: 0.00 // 用于存储待领取奖品金额
  };
},
mounted() {
  this.fetchTotalRewards();
},
methods: {
  fetchTotalRewards() {
    axios.get('/api/total-rewards/')
      .then(response => {
        this.totalRewards = response.data.total_rewards;  // 更新总奖励收入
        this.pendingRewards = response.data.pending_rewards;  // 更新待领取奖品金额
      })
      .catch(error => {
        console.error('获取总奖金收入失败:', error);
      });
  }
}
};
</script>

<style scoped>
.rewards-card {
background: linear-gradient(135deg, #f8f7ee, #f1f0d4);
border-radius: 12px;
padding: 10px;
text-align: center;
margin: 10px;
box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.rewards-header {
font-size: 16px;
font-weight: bold;
color: #333;
margin-bottom: 5px;
}

.rewards-content {
background-color: #fff;
padding: 5px;
border-radius: 8px;
box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rewards-amount {
font-size: 32px;
font-weight: bold;
color: #727272;
display: flex;
align-items: center;
justify-content: center;
}

.coin-icon {
width: 32px;
height: 32px;
margin-right: 8px;
animation: scale 1s ease-in-out infinite;
}

.rewards-footer {
text-align: left;
margin-top: 10px;
font-size: 12px;
color: #666;
}

.claimed-amount {
font-weight: bold;
color: #333;
}

/* 添加SVG缩放动画 */
@keyframes scale {
0%, 100% {
  transform: scale(1);
}
50% {
  transform: scale(1.1);
}
}
</style>

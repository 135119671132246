<template>
  <div class="login-page minimalist">
    <div class="login-logo">
      <img src="@/assets/joker.png" alt="Logo" />
    </div>
    <div class="login-form-container">
      <div class="login-form">
        <div class="button-group">
          <button class="tab-button active">登录</button>
          <button class="tab-button" @click="goToRegister">注册</button>
        </div>
        <el-form @submit.prevent="handleSubmit">
          <el-form-item>
            <label class="required">用户名</label>
            <el-input v-model="form.username" placeholder="用户名" required></el-input>
          </el-form-item>
          <el-form-item>
            <label class="required">密码</label>
            <el-input 
              v-model="form.password" 
              :type="showPassword ? 'text' : 'password'" 
              placeholder="密码" 
              required
            >
              <template #suffix>
                <el-icon
                  @mousedown="togglePasswordVisibility(true)"
                  @mouseup="togglePasswordVisibility(false)"
                  @touchstart="togglePasswordVisibility(true)"
                  @touchend="togglePasswordVisibility(false)"
                >
                  <View />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-link type="primary" class="forgot-password">忘记密码?</el-link>
          </el-form-item>
          <el-form-item>
            <button class="login-button" @click="handleSubmit" :disabled="isDisabled">
              <el-icon class="button-icon">
                <SuccessFilled />
              </el-icon>
              登录
            </button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="login-footer">
      Copyright © 2024. All rights reserved.
    </div>

    <!-- 添加 AlertModal 用于错误或成功提示 -->
    <AlertModal
      v-if="alertVisible"
      :title="alertTitle"
      :message="alertMessage"
      :status="alertStatus"
      v-model:visible="alertVisible"
      @close="alertVisible = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AlertModal from "@/components/AlertModal.vue";  // 引入AlertModal

export default {
  components: {
    AlertModal,  // 注册组件
  },
  data() {
    return {
      activeTab: 'login', // 默认显示登录表单
      form: {
        username: '',
        password: '',
      },
      showPassword: false, // 控制密码是否显示
      alertVisible: false, // 控制AlertModal的可见性
      alertTitle: '',
      alertMessage: '',
      alertStatus: '',  // 可以是'success', 'failed', 'error', 'warning'等
    };
  },
  computed: {
    isDisabled() {
      return !this.form.username || !this.form.password;
    },
    ...mapState('auth', ['isLoggingIn']),
  },
  methods: {
    ...mapActions('auth', ['login']),
    async handleSubmit() {
      if (this.isDisabled || this.isLoggingIn) return; // 检查 isLoggingIn 避免重复登录

      try {
        await this.login(this.form); // 调用 Vuex 的 login action
        this.showAlertModal('成功', '登录成功！', 'success');
        this.$router.push({ name: 'Home' }).then(() => {
          window.location.reload();
        });
      } catch (error) {
        this.showAlertModal('错误', '登录失败，请检查用户名或密码。', 'error');
      }
    },
    goToRegister() {
      this.$router.push({ name: 'Register' }); // 跳转到注册页面
    },
    togglePasswordVisibility(show) {
      this.showPassword = show;
    },
    showAlertModal(title, message, status) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertStatus = status;
      this.alertVisible = true;
    },
  },
};
</script>

<style scoped>
.login-page.minimalist {
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.login-logo img {
  max-width: 200px;
  margin-bottom: 20px;
  display: block;
}

.login-form-container {
  width: 100%;
  max-width: 400px;
  padding: 0 20px; /* 添加左右20px间距 */
  box-sizing: border-box; /* 确保padding不会影响整体宽度 */
}

.login-form {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.button-group {
  display: flex;
  margin-bottom: 20px;
  border-radius: 24px;
  overflow: hidden;
}

.tab-button {
  flex: 1;
  background: none;
  border: none;
  color: #333333;
  font-size: 16px;
  padding: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.tab-button.active {
  background: linear-gradient(135deg, #007bff, #4f3f97);
  color: #fff;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

.tab-button:hover {
  background-color: #e0e0e0;
}

.forgot-password {
  margin-left: auto;
  color: #944ebe;
}

.login-button {
  background: linear-gradient(135deg, #4f3f97, #007bff);
  color: #ffffff;
  border: none;
  border-radius: 24px;
  font-weight: 900;
  font-size: 16px;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.login-button:hover {
  background-color: #4f3f97;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

.login-button[disabled] {
  background-color: #cccccc;
  color: #9e9e9e;
}

.button-icon {
  color: #16db51;
  margin-right: 8px;
}

.login-footer {
  margin-top: 20px;
  color: #999999;
  font-size: 12px;
  text-align: center;
}

.required:after {
  content: '*';
  color: red;
  margin-left: 4px;
  font-size: 14px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .login-form-container {
    max-width: 100%;
    padding: 0 10px; /* 在较小的屏幕上增加间距 */
  }

  .tab-button {
    font-size: 14px;
  }

  .login-button {
    font-size: 14px;
    padding: 10px;
  }
}
</style>

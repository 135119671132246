// store/modules/profile.js
import axios from '@/axios'; // 假设你已经配置好axios

const state = {
  user: {
    avatarUrl: require('@/assets/avatar.jpg'), // 默认头像路径
    full_name: '',
    username: '',
    phone_no: '',
    email: '',
    date_of_birth: '',
    referral_code: '',
    uplineCode: '',
  },
};

const mutations = {
  SET_USER(state, userData) {
    state.user = { ...state.user, ...userData };
  },
  UPDATE_PROFILE(state, updatedData) {
    state.user = { ...state.user, ...updatedData };
  },
};

const actions = {
  fetchUserProfile({ commit }) {
    return axios.get('v1/profile/')
      .then(response => {
        commit('SET_USER', response.data);
      })
      .catch(error => {
        console.error('Failed to fetch user profile:', error);
      });
  },
  updateProfile({ commit }, updatedData) {
    return axios.put('/v1/profile/', updatedData)
      .then(() => {
        commit('UPDATE_PROFILE', updatedData);
      })
      .catch(error => {
        console.error('Failed to update profile:', error);
      });
  },
};

const getters = {
  userProfile(state) {
    return state.user;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <div class="promotion-detail">
    <img :src="imageSrc" alt="Promotion Image" class="promo-image" />
    
    <div class="promo-info">
      <button v-if="!isAuthenticated" class="join-btn">Join now</button>
      <div class="timer">
        <span>Remaining Time</span>
        <div class="countdown">
          <div class="time-box">{{ countdown(id)?.days }}</div>
          <p>Days</p>
          <div class="time-box">{{ countdown(id)?.hours }}</div>
          <p>Hours</p>
          <div class="time-box">{{ countdown(id)?.minutes }}</div>
          <p>Min</p>
          <div class="time-box">{{ countdown(id)?.seconds }}</div>
          <p>Sec</p>
        </div>
      </div>
    </div>

    <div class="promo-content">
      <h2>{{ title }}</h2>
      <h3 class="description">{{ description }}</h3>

      <!-- Event Details, only show if there is data -->
      <div v-if="eventDetails.length > 0">
        <h3>Event Details:</h3>
        <table class="details-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Bonus</th>
              <th>Min. Deposit</th>
              <th>Max. Bonus</th>
              <th>Turnover</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(detail, index) in eventDetails" :key="index">
              <td>{{ detail.product }}</td>
              <td>{{ detail.bonus }}</td>
              <td>MYR {{ detail.minDeposit }}</td>
              <td>{{ detail.maxBonus }}</td>
              <td>x {{ detail.turnover }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Terms & Conditions, always show -->
      <h3>Terms & Conditions:</h3>
      <p>{{ termsConditions }}</p>

      <!-- Turnover Conditions, only show if there is data -->
      <div v-if="turnoverConditions.length > 0">
        <table class="details-table">
          <thead>
            <tr>
              <th>Deposit</th>
              <th>Bonus</th>
              <th>Turnover</th>
              <th>Turnover Required</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(condition, index) in turnoverConditions" :key="index">
              <td>MYR {{ condition.deposit }}</td>
              <td>{{ condition.bonus }}</td>
              <td>x {{ condition.turnover }}</td>
              <td>{{ condition.turnoverRequired }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <ul>
        <li 
          v-for="(step, index) in howToApply" 
          :key="index"
          :class="{'highlighted-term': index === howToApply.length - 1}" 
        >
          {{ step }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from '@/axios';

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: '',
      imageSrc: '',
      description: '',
      eventDetails: [],
      turnoverConditions: [],
      termsConditions: '',
      howToApply: [],
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated', // 通过 Vuex 获取用户是否已登录
      countdown: 'app/countdowns' // Get the countdown from Vuex
    }),
  },
  mounted() {
    this.loadPromotionDetails();
  },
  methods: {
    ...mapActions('app', ['startCountdown']), // Import the startCountdown action

    async loadPromotionDetails() {
      try {
        const response = await axios.get(`/api/promo/collect/${this.id}/`);
        const data = response.data;

        // Construct the full image URL using the axios base URL or a specific backend URL
        this.imageSrc = `${axios.defaults.baseURL}${data.image}`; // Ensure baseURL is correct
        this.title = data.name;
        this.description = data.description;
        this.eventDetails = typeof data.event_details === 'string' ? JSON.parse(data.event_details) : data.event_details;
        this.howToApply = typeof data.terms_list === 'string' ? JSON.parse(data.terms_list) : data.terms_list;
        this.termsConditions = data.terms_conditions;
        this.turnoverConditions = typeof data.turnover_conditions === 'string' ? JSON.parse(data.turnover_conditions) : data.turnover_conditions;

        // Start the countdown via Vuex
        this.startCountdown({ id: this.id, deadline_date: data.deadline_date });

      } catch (error) {
        console.error('Error fetching promotion details:', error);
        alert('Failed to fetch promotion details.');
      }
    },
  }
};
</script>

<style scoped>
.promotion-detail {
  margin: 30px 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ccc;
}

.header h1 {
  font-size: 1.2em;
  margin: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.promo-image {
  width: 100%;
  height: auto;
}

.promo-info {
  padding: 15px;
  text-align: center;
  background-color: #f8f9fa;
}

.join-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #ff9800;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 15px;
}

.timer {
  font-size: 0.9em;
  color: #666;
}

.countdown {
  display: flex;
  justify-content: center;
  gap: 5px;
  font-size: 1.1em;
}

.promo-content {
  padding: 15px;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.details-table th, .details-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.details-table th {
  background-color: #ff9800;
  color: #fff;
}

ul {
  padding-left: 20px;
}

ul li {
  margin-bottom: 5px;
}

/* 添加样式以突出最后一行 */
.highlighted-term {
  color: blue; /* 或者你想要的任何颜色 */
  font-weight: bold;
}

.promo-content h3 {
  margin-top: 20px; /* 增加标题之间的间距 */
}

.description {
  margin-bottom: 15px; /* 增加 description 和下方内容的间距 */
}

.time-box {
  color: #007bff;
  font-size: 14px;
  font-weight: bold;
}
</style>

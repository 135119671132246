<template>
  <div class="prize-winner">
    <div class="header">
      <h2>中奖用户</h2>
      <span class="live-indicator">LIVE <el-icon><VideoCameraFilled /></el-icon></span>
    </div>
    <table class="winner-table">
      <thead>
        <tr>
          <th>用户名</th>
          <th>日期</th>
          <th>奖金</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(winner, index) in winners.slice(0, 10)" :key="index">
          <td>{{ winner.username }}</td>
          <td>{{ winner.date }}</td>
          <td class="highlight">
            {{ winner.prize }}
            <span class="coin-icon" v-html="coinSvg"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from '@/axios';

export default {
  data() {
    return {
      coinSvg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fcba03" class="coin-animation">
<path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z" />
<path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z" clip-rule="evenodd" />
</svg>`,
      winners: [],
    };
  },
  created() {
    this.fetchLatestWinners();
  },
  methods: {
    fetchLatestWinners() {
      axios.get('/api/latest-winners/')  // 这里替换为你实际的API端点
        .then(response => {
          this.winners = response.data;
        })
        .catch(error => {
          console.error('Error fetching latest winners:', error);
        });
    },
  },
};
</script>

<style scoped>
.prize-winner {
    margin: 10px;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.live-indicator {
    display: flex;
    align-items: center;
    background-color: #ff4b5c;
    padding: 3px 8px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    animation: live-flash 1s infinite;
}

@keyframes live-flash {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.winner-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
}

.winner-table th,
.winner-table td {
    padding: 10px;
    text-align: center;
    font-size: 12px;
    border: 1px solid #31363f;
}

.winner-table th {
    background-color: #2c3039;
    color: #ffffff;
}

.winner-table td {
    background-color: #23262e;
    color: #ffffff; /* 将用户名和日期的颜色设置为白色 */
}

.winner-table td.highlight {
    color: #fcba03; /* 奖金部分保持金黄色 */
}

.coin-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    vertical-align: middle;
}

.highlight {
    color: #fcba03;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coin-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    vertical-align: middle;
}

.coin-animation {
    animation: rotate-coin 2s linear infinite;
}

@keyframes rotate-coin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>

<template>
  <div class="container" ref="shareArea">
    <div class="avatar-container">
      <img :src="userProfile.avatarUrl || userAvatar" alt="User Avatar" class="avatar" />
    </div>
    <div class="invite-share-area" ref="shareArea">
    <p class="invite-title">邀请好友赚取额外奖励！</p>
    <p class="masked-id">{{ maskedUserId }}</p>
    <p class="invite-instructions">
      使用下面的唯一二维码邀请好友加入并赚取奖励。
    </p>
    <div class="qr-container">
      <div class="qr-frame">
        <canvas ref="qrCanvas" class="qr-code"></canvas>
        <div class="qr-corners">
          <div class="corner top-left"></div>
          <div class="corner top-right"></div>
          <div class="corner bottom-left"></div>
          <div class="corner bottom-right"></div>
        </div>
      </div>
    </div>
    <p class="invite-code">{{ inviteCode }}</p>
  </div>
    <el-button class="share-button" @click="shareContent">分享整个图片</el-button>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { mapGetters } from "vuex";
import html2canvas from "html2canvas"; // 引入 html2canvas

export default {
  data() {
    return {
      qrContent: null, // 存储二维码内容
      qrGenerated: false, // 标记二维码是否已经生成
    };
  },
  computed: {
    ...mapGetters('profile', ['userProfile']),
    maskedUserId() {
      const username = this.userProfile.username;
      return username ? `${username.charAt(0)}*****${username.slice(-2)}` : "G********6";
    },
    inviteCode() {
      return this.userProfile.referral_code || "RB58346F8"; // 使用真实的推荐码或默认值
    },
    referralLink() {
      return this.userProfile.referral_link || ''; // 使用后端返回的 referral_link
    },
  },
  mounted() {
    // 页面加载时只生成一次二维码
    if (!this.qrGenerated) {
      this.generateQRCode();
      this.qrGenerated = true; // 设置为已生成
    }
  },
  methods: {
    generateQRCode() {
      // 移除时间戳，二维码内容保持不变
      if (this.referralLink) {
        this.qrContent = this.referralLink; // 只包含推荐链接
        this.drawQRCode();
      }
    },
    drawQRCode() {
      const canvas = this.$refs.qrCanvas;
      // 清除 canvas 上的旧二维码
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      QRCode.toCanvas(canvas, this.qrContent, { width: 220 }, (error) => {
        if (error) console.error("二维码生成失败:", error);
        else console.log("二维码生成成功");
      });
    },
    shareContent() {
      const shareArea = this.$refs.shareArea;

      // 使用 html2canvas 将分享区域转换为图片
      html2canvas(shareArea).then((canvas) => {
        canvas.toBlob((blob) => {
          const file = new File([blob], "invite.png", { type: "image/png" });

          // 检查是否支持 Web Share API
          if (navigator.share) {
            navigator.share({
              title: '我的邀请图片',
              text: '邀请好友使用此二维码加入并赚取奖励。',
              files: [file],
            })
            .then(() => {
              this.$message.success("邀请图片分享成功！");
            })
            .catch((error) => {
              console.error("分享失败：", error);
              this.$message.error("分享邀请图片时出错。");
            });
          } else {
            this.$message.error("当前浏览器不支持分享功能。");
          }
        });
      });
    }
  },
  watch: {
    inviteCode(newVal, oldVal) {
      if (newVal !== oldVal && !this.qrGenerated) {
        this.generateQRCode(); // 如果 inviteCode 改变，重新生成二维码
      }
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;  /* 确保容器内的所有内容水平居中 */
  justify-content: center;  /* 垂直居中 */
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin: 10px;
  margin-top: 80px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.avatar-container {
  position: absolute;
  top: -50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #ffffff;
  background-color: #f0f0f0;
}

.invite-title {
  color: #2b4d8b;
  font-weight: bold;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
}

.masked-id {
  font-weight: 900;
  font-size: 14px;
  color: #000000;
  margin-bottom: 5px;
  text-align: center;
}

.invite-instructions {
  color: #666666;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-frame {
  position: relative;
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code {
  width: 200px;
  height: 200px;
}

.qr-corners {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.corner {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid #F09819;
}

.top-left {
  top: 15px;
  left: 15px;
  border-right: none;
  border-bottom: none;
}

.top-right {
  top: 15px;
  right: 15px;
  border-left: none;
  border-bottom: none;
}

.bottom-left {
  bottom: 15px;
  left: 15px;
  border-right: none;
  border-top: none;
}

.bottom-right {
  bottom: 15px;
  right: 15px;
  border-left: none;
  border-top: none;
}

.invite-code {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  text-align: center;  /* 确保文字在容器中居中 */
  bottom: 0;
  transform: translateY(-170%);
}

.share-button {
  background-color: #2b4d8b;
  color: #ffffff;
  font-weight: bold;
  border-radius: 5px;
}
</style>

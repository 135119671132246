import axios from '@/axios';

const state = {
  banners: [],
  noticeText: '',
  promotions: [],   // 存储所有的推广活动
  countdowns: {},   // 存储每个推广活动的倒计时信息
  intervals: [],    // 存储所有定时器的引用，以便于销毁
};

const getters = {
  allBanners(state) {
    return state.banners;
  },
  noticeText(state) {
    return state.noticeText;
  },
  promotions(state) {
    return state.promotions;
  },
  countdowns(state) {
    return (id) => state.countdowns[id];
  },
};

const actions = {
  async fetchBanners({ commit }) {
    try {
      const response = await axios.get('/api/public-banners/');
      commit('setBanners', response.data);
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  },
  async fetchNotice({ commit }) {
    try {
      const response = await axios.get('/api/notice/');
      commit('setNoticeText', response.data.text);
    } catch (error) {
      console.error('Error fetching notice:', error);
    }
  },
  
  async fetchPromotions({ commit, dispatch }) {
    try {
      const response = await axios.get('/api/promo/collect/');
      const promotions = response.data.map((promo) => {
        return {
          id: promo.id,
          image: `${axios.defaults.baseURL}${promo.image}`,
          name: promo.name,
          description: promo.description,
          deadline_date: promo.deadline_date,
        };
      });
      commit('setPromotions', promotions);
      promotions.forEach(promo => {
        dispatch('startCountdown', promo);
      });
    } catch (error) {
      console.error('Error fetching promotions:', error);
    }
  },
  startCountdown({ commit }, promo) {
    const deadlineDate = new Date(promo.deadline_date).getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = deadlineDate - now;
  
      if (distance < 0) {
        commit('updateCountdown', { id: promo.id, remainingTime: { days: '0', hours: '00', minutes: '00', seconds: '00' } });
        clearInterval(interval);
        return;
      }
  
      const days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString();
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
      const seconds = Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0');
  
      commit('updateCountdown', { id: promo.id, remainingTime: { days, hours, minutes, seconds } });
    }, 1000);
  
    commit('addInterval', interval);
  },
  clearIntervals({ commit, state }) {
    state.intervals.forEach(interval => clearInterval(interval));
    commit('clearIntervals');
  }
};

const mutations = {
  setBanners(state, banners) {
    state.banners = banners;
  },
  setNoticeText(state, text) {
    state.noticeText = text;
  },
  setPromotions(state, promotions) {
    state.promotions = promotions;
  },
  updateCountdown(state, { id, remainingTime }) {
    state.countdowns = {
      ...state.countdowns,
      [id]: remainingTime,
    };
  },
  addInterval(state, interval) {
    state.intervals.push(interval);
  },
  clearIntervals(state) {
    state.intervals = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

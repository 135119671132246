<template>
  <div class="home">
      <TopBanner /> 
      <AngpawView />
      <LatestWinnersTable />
      <TopGames />
      <LatestPromo />
  </div>
</template>

<script>
import TopBanner from '@/views/Home/Components/TopBanner.vue'
import AngpawView from '@/views/Home/Components/AngpawView.vue'
import LatestPromo from '@/views/Home/Components/LatestPromo.vue'
import LatestWinnersTable from '@/views/Home/Components/LatestWinnersTable.vue'
import TopGames from '@/views/Home/Components/TopGames.vue'

export default {
  components: {
    TopBanner,
    AngpawView,
    LatestPromo,
    LatestWinnersTable,
    TopGames,
   },
};
</script>



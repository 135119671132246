<template>
  <div class="app-container">
    <TopNav />
    <div class="main-content">
      <Loading />
      <router-view />
    </div>
    <BottomNav />
  </div>
</template>

<script>
import TopNav from './components/TopNav.vue';
import BottomNav from './components/BottomNav.vue';
import Loading from './components/AppLoading.vue';

export default {
  components: {
    TopNav,
    BottomNav,
    Loading
  }
}
</script>

<style>

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  background: #eef0f8;
  font-size: 14px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 改为min-height以解决100vh在iOS上可能的问题 */
}

.main-content {
  flex-grow: 1; /* 自动扩展 */
  overflow-y: auto; /* 内容溢出时启用垂直滚动 */
  -webkit-overflow-scrolling: touch; /* 为iOS设备优化滚动 */
  margin-top: 50px; /* 为顶部导航栏留出空间 */
  margin-bottom: 60px; /* 为底部导航栏留出空间 */
}

</style>

<template>
  <div class="container">
    <el-form>
      <el-form-item class="form-label" label="Current Password">
        <el-input
          v-model="form.currentPassword"
          :type="passwordVisibility.currentPassword ? 'text' : 'password'"
          placeholder="Enter Current Password"
        >
          <template #suffix>
            <el-icon
              @mousedown="togglePasswordVisibility('currentPassword', true)"
              @mouseup="togglePasswordVisibility('currentPassword', false)"
              @touchstart="togglePasswordVisibility('currentPassword', true)"
              @touchend="togglePasswordVisibility('currentPassword', false)"
            >
              <View v-if="!passwordVisibility.currentPassword" />
              <ViewOff v-else />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item class="form-label" label="New Password">
        <el-input
          v-model="form.newPassword"
          :type="passwordVisibility.newPassword ? 'text' : 'password'"
          placeholder="Enter New Password"
        >
          <template #suffix>
            <el-icon
              @mousedown="togglePasswordVisibility('newPassword', true)"
              @mouseup="togglePasswordVisibility('newPassword', false)"
              @touchstart="togglePasswordVisibility('newPassword', true)"
              @touchend="togglePasswordVisibility('newPassword', false)"
            >
              <View v-if="!passwordVisibility.newPassword" />
              <ViewOff v-else />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item class="form-label" label="Confirm Password">
        <el-input
          v-model="form.confirmPassword"
          :type="passwordVisibility.confirmPassword ? 'text' : 'password'"
          placeholder="Confirm New Password"
        >
          <template #suffix>
            <el-icon
              @mousedown="togglePasswordVisibility('confirmPassword', true)"
              @mouseup="togglePasswordVisibility('confirmPassword', false)"
              @touchstart="togglePasswordVisibility('confirmPassword', true)"
              @touchend="togglePasswordVisibility('confirmPassword', false)"
            >
              <View v-if="!passwordVisibility.confirmPassword" />
              <ViewOff v-else />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>

      <el-button
        class="btn-button"
        :disabled="isSubmitDisabled"
        @click="handleSubmit"
        :loading="loading"
      >
        Submit
      </el-button>

      <el-alert
        v-if="errorMessage"
        type="error"
        :closable="false"
        title="Error"
        :description="errorMessage"
      />

      <el-alert
        v-if="successMessage"
        type="success"
        :closable="false"
        title="Success"
        :description="successMessage"
      />
    </el-form>
  </div>
</template>

<script>
// 确保你在 src/axios.js 中导出了 axios 实例
import axiosInstance from '@/axios';  // 这里使用你定义好的 axios 实例
import { mapActions } from 'vuex';  // 导入 mapActions 来调用 Vuex actions

export default {
  data() {
    return {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        refreshToken: ''  // 假设 refresh token 是可用的
      },
      passwordVisibility: {
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
      },
      loading: false,
      errorMessage: '',
      successMessage: ''
    };
  },
  computed: {
    isSubmitDisabled() {
      return (
        !this.form.currentPassword ||
        !this.form.newPassword ||
        !this.form.confirmPassword ||
        this.loading
      );
    }
  },
  methods: {
    ...mapActions(['logout']),  // 使用 mapActions 映射 Vuex 的 logout 动作到本组件的方法中

    togglePasswordVisibility(field, value) {
      this.passwordVisibility[field] = value;
    },
    async handleSubmit() {
      this.loading = true;
      this.errorMessage = '';
      this.successMessage = '';

      try {
        const response = await axiosInstance.put('/api/change-password/', {  // 使用 axiosInstance 而不是 axios
          current_password: this.form.currentPassword,
          new_password: this.form.newPassword,
          confirm_new_password: this.form.confirmPassword,
          refresh_token: this.form.refreshToken // 传递 refresh token 以便注销
        });

        if (response.status === 200) {
          this.successMessage = response.data.detail;

          // 在密码更改成功后，调用 Vuex 的 logout 动作
          await this.logout();

          // 跳转到登录页面或其他需要的页面
          this.$router.push({ name: 'Login' });
        } else {
          this.errorMessage = response.data.detail;
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.detail || '发生了错误';
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>




<template>
  <div class="account-container">
    <div class="account-header">
      <span class="header-title">My Account</span>
    </div>
    <div class="account-list">
      <div class="account-item" @click="goToProfile">
        <el-icon style="font-size: 24px; color: #f8b133;">
          <User />
        </el-icon>
        <span class="item-text">My Profile</span>
        <el-icon class="arrow-icon" style="font-size: 24px;">
          <ArrowRight />
        </el-icon>
      </div>
      <div class="account-item" @click="goToBankAccount">
        <el-icon style="font-size: 24px; color: #349eff;">
          <CreditCard />
        </el-icon>
        <span class="item-text">Bank Account</span>
        <el-icon class="arrow-icon" style="font-size: 24px;">
          <ArrowRight />
        </el-icon>
      </div>
      <div class="account-item" @click="goToSecurity">
        <el-icon style="font-size: 24px; color: #4bb34b;">
          <Lock />
        </el-icon>
        <span class="item-text">Change Password</span>
        <el-icon class="arrow-icon" style="font-size: 24px;">
          <ArrowRight />
        </el-icon>
      </div>
      <div class="account-item" @click="goToInbox">
        <el-icon style="font-size: 24px; color: #ff5656;">
          <MessageBox />
        </el-icon>
        <span class="item-text">Inbox</span>
        <el-icon class="arrow-icon" style="font-size: 24px;">
          <ArrowRight />
        </el-icon>
      </div>
    </div>
<el-button class="btn-button" @click="confirmLogout">
  <el-icon style="font-size: 20px;">
    <SwitchButton />
  </el-icon>
  <span>Logout</span>
</el-button>
  </div>
</template>

<script>
export default {
  methods: {
    goToProfile() {
      this.$router.push({ name: 'Profile' });
    },
    goToBankAccount() {
      this.$router.push({ name: 'BankAccount' });
    },
    goToSecurity() {
      this.$router.push({ name: 'ChangePassword' });
    },
    goToInbox() {
      this.$router.push({ name: 'Notification' });
    },
    confirmLogout() {
      this.$confirm('你确定要注销吗?', '确认注销', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('auth/logout');
      }).catch(() => {
        // 捕获取消操作, 避免未处理的 Promise 拒绝错误
      });
    },
  },
};
</script>

<style scoped>
.account-container {
  padding: 20px 0;
  margin: 0 10px;
}

.account-header {
  padding: 15px;
  text-align: left;
}

.header-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.account-list {
  background-color: #fff;
  border-radius: 0 0 12px 12px;
}

.account-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.account-item:last-child {
  border-bottom: none;
}

.item-text {
  flex: 1;
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

.arrow-icon {
  color: #bbb;
}

</style>

import axios from '@/axios';

const state = {
  redPackets: [],
  redPacket: null,
  remainingGrabs: 0, // 用于追踪剩余的领取次数
  grabbedPacket: null, // 存储领取的红包信息
  availableRedPackets: [], // 用于转移的可用红包
};

const mutations = {
  SET_RED_PACKETS(state, payload) {
    state.redPackets = payload;
  },
  SET_RED_PACKET(state, payload) {
    state.redPacket = payload;
  },
  UPDATE_RED_PACKET(state, payload) {
    const index = state.redPackets.findIndex(packet => packet.id === payload.id);
    if (index !== -1) {
      state.redPackets.splice(index, 1, payload);
    }
  },
  UPDATE_CHIPS_BALANCE(state, amount) {
    if (state.user) {
      state.user.chips_balance += amount;
    }
  },
  SET_REMAINING_GRABS(state, payload) {
    state.remainingGrabs = payload;
  },
  SET_GRABBED_PACKET(state, payload) {
    state.grabbedPacket = payload;
  },
  SET_AVAILABLE_RED_PACKETS(state, payload) {
    state.availableRedPackets = payload;
  },
};

const actions = {
  fetchRedPackets({ commit }) {
    return axios.get('/api/redpacket/')
      .then(response => {
        commit('SET_RED_PACKETS', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching red packets:', error);
        throw error;
      });
  },
  fetchRedPacketById({ commit }, id) {
    return axios.get(`/api/redpacket/${id}/`)
      .then(response => {
        commit('SET_RED_PACKET', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching red packet:', error);
        throw error;
      });
  },
  fetchRedPacket({ commit }) {
    return axios.get('/api/redpacket/active/')
      .then(response => {
        commit('SET_RED_PACKET', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching red packet:', error);
        throw error;
      });
  },
  createRedPacket({ dispatch }, form) {
    return axios.post('/api/redpacket/', form)
      .then(() => {
        dispatch('fetchRedPackets');
        return { message: '红包活动创建成功' };
      })
      .catch(error => {
        console.error('Error creating red packet:', error);
        throw error;
      });
  },
  updateRedPacket({ commit }, form) {
    return axios.put(`/api/redpacket/${form.id}/`, form)
      .then(response => {
        commit('UPDATE_RED_PACKET', response.data);
        return { message: '红包活动更新成功' };
      })
      .catch(error => {
        console.error('Error updating red packet:', error);
        throw error;
      });
  },
  deleteRedPacket({ dispatch }, id) {
    return axios.delete(`/api/redpacket/${id}/`)
      .then(() => {
        dispatch('fetchRedPackets');
        return { message: '红包活动删除成功' };
      })
      .catch(error => {
        console.error('Error deleting red packet:', error);
        throw error;
      });
  },
  grabRedPacket({ commit, dispatch }) {
    return axios.post('/api/redpacket/grab/')
      .then(response => {
        if (response.status === 200) {
          commit('UPDATE_CHIPS_BALANCE', response.data.amount);
          commit('SET_GRABBED_PACKET', response.data);
          dispatch('fetchRedPackets');
        }
        return response;
      })
      .catch(error => {
        console.error('Error grabbing red packet:', error);
        throw error;
      });
  },
  fetchRemainingGrabs({ commit }) {
    return axios.get('/api/user-remaining-grabs/')
      .then(response => {
        commit('SET_REMAINING_GRABS', response.data.remaining_grabs);
        return response.data.remaining_grabs;
      })
      .catch(error => {
        console.error('Error fetching remaining grabs:', error);
        throw error;
      });
  },
  fetchAvailableRedPackets({ commit }) {
    return axios.get('/api/redpacket/available/')
      .then(response => {
        commit('SET_AVAILABLE_RED_PACKETS', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching available red packets:', error);
        throw error;
      });
  },
  transferRedPacket({ dispatch }, form) {
    return axios.post('/api/transfer/', form)
      .then(() => {
        dispatch('fetchAvailableRedPackets');
        return { message: '红包转移成功' };
      })
      .catch(error => {
        console.error('Error transferring red packet:', error);
        throw error;
      });
  },
};

const getters = {
  redPackets(state) {
    return state.redPackets;
  },
  redPacket(state) {
    return state.redPacket;
  },
  remainingGrabs(state) {
    return state.remainingGrabs;
  },
  grabbedPacket(state) {
    return state.grabbedPacket;
  },
  availableRedPackets(state) {
    return state.availableRedPackets;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <div class="overview-container">
    <div class="header">
      <div class="title-section">
        <el-icon>
          <CreditCard />
        </el-icon>
        <span class="title">Overview</span>
      </div>
      <div class="balance-info">
        <span>Total Balance</span>
        <div class="balance">
          <span class="balance-value" :style="{ color: balanceColor }">MYR {{ chipsBalance.toFixed(2) }}</span>
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="action-item" @click="navigateTo('/deposit')">
        <el-icon style="font-size: 24px; color: #a586ff;">
          <CreditCard />
        </el-icon>
        <span>Deposit</span>
      </div>
      <div class="divider"></div>
      <div class="action-item" @click="navigateTo('/withdraw')">
        <el-icon style="font-size: 24px; color: #ff7171;">
          <Wallet />
        </el-icon>
        <span>Withdraw</span>
      </div>
      <div class="divider"></div>
      <div class="action-item" @click="navigateTo('/transfer')">
        <el-icon style="font-size: 24px; color: #4fb4ff;">
          <Refresh />
        </el-icon>
        <span>Transfer</span>
      </div>
      <div class="divider"></div>
      <div class="action-item" @click="navigateTo('/history')">
        <el-icon style="font-size: 24px; color: #ffc700;">
          <Document />
        </el-icon>
        <span>History</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chipsBalance: {
      type: Number,
      required: true,
      default: 0,
    },
    balanceColor: {
      type: String,
      default: '#80734e',
    },
    iconColor: {
      type: String,
      default: '#ffc700',
    },
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.overview-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 10px;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between; /* 将子元素对齐到容器的两端 */
  align-items: center;
  padding-bottom: 20px;
}

.title-section {
  display: flex;
  align-items: center;
  background-color: #007bff;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-left: 8px;
}

.balance-info {
  text-align: right;
}

.balance-info span {
  display: block;
  color: #666;
}

.balance {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.balance-value {
  margin-left: 8px;
  font-size: 24px;
  font-weight: bold;
}

.actions {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
}

.action-item {
  text-align: center;
  flex: 1;
}

.action-item span {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.divider {
  width: 1px;
  background-color: #e0e0e0;
  height: 50px;
  align-self: center;
}

.animated-icon {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>

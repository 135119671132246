<template>
            <ChipBalance />
    <el-card>
      <h2>转移红包到游戏</h2>
      <el-form :model="form" label-width="120px">
        <el-form-item label="选择红包" label-position="top">
          <el-select v-model="form.red_packet_record_id" placeholder="请选择红包">
            <el-option v-for="record in availableRedPackets" :key="record.id" :label="`${record.activity_name} - ${record.amount}`" :value="record.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="红包金额" label-position="top">
          <el-input v-model="selectedRedPacketAmount" disabled></el-input>
        </el-form-item>

        <el-form-item label="选择游戏" label-position="top">
          <div class="game-flex-container">
            <div
              v-for="(game, index) in games"
              :key="index"
              class="game-item"
              :class="{ selected: selectedGame === game.name }"
              @click="selectGame(game.name)"
            >
              <div class="game-logo-wrapper">
                <img :src="game.logo" :alt="game.name" class="game-logo" />
              </div>
              <span class="game-name">{{ game.name }}</span>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="transferRedPacket">确认转移</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 使用 AlertModal 组件 -->
    <AlertModal
      :visible="showAlert"
      :title="alertTitle"
      :message="alertMessage"
      :status="alertStatus"
      @update:visible="showAlert = $event"
    />
</template>

<script>
import axios from '@/axios';
import ChipBalance from '@/components/ChipBalance.vue'
import AlertModal from '@/components/AlertModal.vue';  // 确保正确导入AlertModal
import { games } from '@/game.js';

export default {
  components: {
    AlertModal,
    ChipBalance,
  },
  data() {
    return {
      form: {
        red_packet_record_id: null,
        game_name: null,
      },
      availableRedPackets: [], // 可用红包列表
      games: games, // 从 game.js 中导入游戏列表
      selectedGame: '', // 默认选择的游戏名称
      showAlert: false, // 控制弹窗显示
      alertTitle: '',
      alertMessage: '',
      alertStatus: 'success',
    };
  },
  created() {
    this.fetchAvailableRedPackets(); // 获取可用红包
  },
  computed: {
    selectedRedPacketAmount() {
      const selectedRecord = this.availableRedPackets.find(
        record => record.id === this.form.red_packet_record_id
      );
      return selectedRecord ? selectedRecord.amount : '0.00'; // 如果未选择红包，则默认显示0.00
    }
  },
  methods: {
    fetchAvailableRedPackets() {
      axios.get('/api/available-red-packets/')
        .then(response => {
          this.availableRedPackets = response.data;
        })
        .catch(error => {
          console.error('Error fetching available red packets:', error);
        });
    },
    selectGame(gameName) {
      this.selectedGame = gameName;
      this.form.game_name = gameName;
    },
    transferRedPacket() {
      if (!this.form.game_name) {
        // 如果没有选择游戏，显示提示框
        this.showAlertModal('错误', '请先选择一个游戏', 'failed');
        return;
      }

      axios.post('/api/transfer-red-packet/', this.form)
        .then(() => {
          this.showAlertModal('成功', '转移提交成功', 'success');
        })
        .catch(() => {
          this.showAlertModal('错误', '提交转移失败', 'failed');
        });
    },
    showAlertModal(title, message, status) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertStatus = status;
      this.showAlert = true;
    },
  }
};
</script>

<style scoped>
.game-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.game-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 5px;
  border: none;
  border-radius: 8px;
  transition: transform 0.2s ease, border-color 0.2s ease;
}

.game-item.selected .game-logo-wrapper {
  border-color: #f09819;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.game-logo-wrapper {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.game-logo {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  filter: grayscale(100%);
}

.game-item.selected .game-logo {
  filter: none;
}

.game-name {
  font-size: 11px;
  font-weight: 700;
  text-align: center;
}
</style>

import axios from 'axios';
import store from '@/store';
import router from '@/router';

const instance = axios.create({
  baseURL: 'https://api.easy88win.co/',  // 后端 API 地址
  timeout: 10000,
  withCredentials: true,  // 确保跨域请求时会携带 cookie
});

let isRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (newToken) => {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

// 请求拦截器
instance.interceptors.request.use(
  async (config) => {
    const tokenExpirationDate = localStorage.getItem('token_expiration_date');
    
    if (!tokenExpirationDate) return config;

    const expirationDate = new Date(tokenExpirationDate);
    const now = new Date();

    // 如果 token 将在 5 分钟内过期
    if (expirationDate.getTime() - now.getTime() < 5 * 60 * 1000 && !isRefreshing) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const response = await store.dispatch('auth/refreshToken');
          const newToken = response.data.access;
          localStorage.setItem('token', newToken);
          config.headers['Authorization'] = `Bearer ${newToken}`;
          isRefreshing = false;
          onTokenRefreshed(newToken);
        } catch (error) {
          isRefreshing = false;
          throw new Error('Token 刷新失败');
        }
      }
    } else if (isRefreshing) {
      // 如果 token 正在刷新，等待刷新完成
      return new Promise((resolve) => {
        addRefreshSubscriber((newToken) => {
          config.headers['Authorization'] = `Bearer ${newToken}`;
          resolve(config);
        });
      });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // 如果返回 401 未授权，触发登出
      store.dispatch('auth/logout').then(() => {
        router.push('/login');
      });
    } else if (error.response && error.response.status === 500) {
      console.error('服务器错误: 服务器出错，请稍后重试。');
    } else {
      console.error('网络错误: 无法连接到服务器，请检查网络连接。');
    }
    return Promise.reject(error);
  }
);

export default instance;

<template>
  <div class="red-envelope-container">
    <img src="@/assets/333.png" alt="Red Envelope" class="red-envelope-image" />
    <div class="info-bar">
      <div class="info-item">
        <div class="info-icon-text">
          <el-icon class="custom-icon"><WalletFilled /></el-icon>
          <div class="info-text">
            <div class="info-amount">{{ chipsBalance.toFixed(2) }}</div>
            <div class="info-label">Balance</div>
          </div>
        </div>
      </div>

      <el-divider direction="vertical"></el-divider>

      <div class="info-item">
        <div class="info-icon-text">
          <el-icon class="custom-icon"><Coin /></el-icon>
          <div class="info-text">
            <div class="info-amount">x{{ remainingQuota }}</div>
            <div class="info-label">Daily Quota</div>
          </div>
        </div>
      </div>

      <el-divider direction="vertical"></el-divider>

      <div class="info-item" :style="{ width: '100px' }">
        <div class="info-icon-text">
          <el-icon class="custom-icon"><Tickets /></el-icon>
          <div class="info-text">
            <div class="info-amount">x{{ remainingGrabs }}</div>
            <div class="info-label">Quota Left</div>
          </div>
        </div>
      </div>

      <el-divider direction="vertical"></el-divider>

      <div class="info-item grab-item">
        <el-button 
          type="danger" 

          icon="Ticket"
          class="grab-button" 
          @click="goToGrabPage" 
          :disabled="!activityId">
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';

export default {
  data() {
    return {
      chipsBalance: 0,
      remainingGrabs: 10, // 初始为0，未登录用户看到的值
      remainingQuota: 1000, // 初始为0，未登录用户看到的值
      activityId: null,
    };
  },
  mounted() {
    this.fetchUserInfo();
    this.fetchActiveActivity();
  },
  methods: {
    fetchUserInfo() {
      axios.get('v1/profile/')
        .then(response => {
          this.chipsBalance = response.data.chips_balance;
        })
        .catch(error => {
          console.error('Error fetching user info:', error);
        });
    },
    fetchActiveActivity() {
      axios.get('api/active-lucky-envelope/')
        .then(response => {
          // 检查是否有活动数据
          if (response.data) {
            const { activityId, remainingGrabs, remainingQuota } = response.data;
            this.activityId = activityId;
            this.remainingGrabs = remainingGrabs;
            this.remainingQuota = remainingQuota;
          } else {
            // 如果没有活动，清空数据
            this.activityId = null;
            this.remainingGrabs = 0;
            this.remainingQuota = 0;
          }
        })
        .catch(error => {
          console.error('Error fetching active activity:', error);
          // 在请求失败时清空数据
          this.activityId = null;
          this.remainingGrabs = 0;
          this.remainingQuota = 0;
        });
    },
    goToGrabPage() {
      if (this.activityId) {
        this.$router.push('/redpacket');
      }
    }
  }
}
</script>

<style scoped>
.red-envelope-container {
  width: 100%;
  margin: 50px 0;
  position: relative;
}

.red-envelope-image {
  display: block;
  margin: 0 auto;
  max-width: 80%;
  margin-bottom: -100px;
  z-index: 1;
  position: relative;
}

.info-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  position: relative;
  z-index: 2;
}

.info-item {
  flex-shrink: 1;
  text-align: center;
}

.info-icon-text {
  display: flex;
  align-items: center;
}

.custom-icon {
  font-size: 28px;
  color: #f5a623;
  margin-right: 8px;
}

.info-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-amount {
  font-size: 16px;
  font-weight: bold;
  color: #80734e;
}

.info-label {
  font-weight: bold;
  font-size: 10px;
  color: #797979;
}

.el-divider {
  height: 40px;
}

.grab-button {
  height: 30px;
  width: 30px;
  font-size: 10px;
  font-weight: bold;
}
</style>

<template>
  <div class="account-page">
    <div class="profile">
      <img :src="user.avatarUrl" alt="User Avatar" class="avatar circle" />
      <div class="welcome-text">
        <span>Welcome back,</span>
        <div class="username-section">
          <span class="username">{{ username }}</span> <!-- 显示用户名 -->
        </div>
      </div>
    </div>
  </div>
  <UserOverview :chips-balance="chipsBalance" />
  <UserVerify />
  <UserNav @logout="logout" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserOverview from '@/views/Profile/Components/UserOverview.vue';
import UserVerify from '@/views/Profile/Components/UserVerify.vue';
import UserNav from '@/views/Profile/Components/UserNav.vue';

export default {
  components: {
    UserOverview,
    UserVerify,
    UserNav,
  },
  computed: {
    ...mapGetters('auth', ['getUsername', 'getChipsBalance']),
    username() {
      return this.getUsername || 'Guest';
    },
    chipsBalance() {
      return this.getChipsBalance || 0; // 确保 chipsBalance 有默认值
    }
  },
  data() {
    return {
      user: {
        avatarUrl: require('@/assets/avatar.jpg'),
      },
    };
  },
  methods: {
    ...mapActions('auth', ['logout', 'fetchUsername']),
    changeUsername() {
      console.log('Change username clicked');
    },
  },
  created() {
    this.fetchUsername();
  },
};
</script>

<style scoped>
.account-page {
  display: flex;
  align-items: center;
  background: linear-gradient(to top, #edf3fc, #fff2de); /* 渐变从上到下 */
  padding: 20px;
  margin-bottom: 10px;
}

.profile {
  display: flex;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border: 2px solid #ffffff; /* 为头像添加白色边框 */
}

.circle {
  border-radius: 50%; /* 确保头像是圆形的 */
}

.welcome-text {
  display: flex;
  flex-direction: column;
}

.username-section {
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.username {
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;
}
</style>

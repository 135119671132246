<template>
  <div>
    <!-- "Add New Bank Account" 按钮 -->
    <div class="add-bank-account-container" @click="goToAddBankAccount">
      <div class="add-bank-account-box">
        <el-icon class="add-icon">
          <CirclePlusFilled />
        </el-icon>
        <span class="add-text">Add New Bank Account</span>
      </div>
    </div>

    <!-- 已有的银行账户列表 -->
    <div v-if="banks.length > 0" class="bank-account-container">
      <div
        v-for="(bank, index) in banks"
        :key="index"
        class="bank-account-box"
      >
        <img :src="getBankLogo(bank.bank_branch)" class="bank-logo" />
        <div class="bank-details">
          <span class="bank-name">{{ getBankFullName(bank.bank_branch) }}</span>
          <span class="bank-holder-name">{{ bank.bank_holder_name }}</span>
          <span class="bank-account-number">{{ formatBankAccountNumber(bank.bank_account_number) }}</span>
        </div>
        <el-button 
          v-if="!bank.is_primary" 
          type="danger" 
          icon="Delete" 
          size="large" 
          @click="confirmDelete(bank.id)" 
          circle
        ></el-button>
      </div>
    </div>

<AlertModal
  :title="alertTitle"
  :message="alertMessage"
  :status="alertStatus"
  :visible="showAlert"
  :isConfirmation="isConfirmation"
  @confirm="deleteBank"
  @close="handleModalClose"
  @update:visible="showAlert = $event"
/>
  </div>
</template>

<script>
import axios from '@/axios';
import AlertModal from '@/components/AlertModal.vue';
import { banks } from '@/banks.js';

export default {
  components: { AlertModal },
  data() {
    return {
      banks: [],
      selectedBankId: null,
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      alertStatus: 'success',
      isConfirmation: false,
    };
  },
  methods: {
    goToAddBankAccount() {
      this.$router.push({ name: 'AddBank' });
    },
    async fetchBanks() {
      try {
        const response = await axios.get('/api/banks/');
        this.banks = response.data;
      } catch (error) {
        console.error('Failed to fetch bank accounts:', error);
      }
    },
    getBankFullName(shortName) {
      const bank = banks.find(bank => bank.shortName === shortName);
      return bank ? bank.fullName : shortName;
    },
    getBankLogo(shortName) {
      const bank = banks.find(bank => bank.shortName === shortName);
      return bank ? bank.logo : '';
    },
    formatBankAccountNumber(accountNumber) {
      return accountNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
    },
  confirmDelete(bankId) {
  console.log("Selected Bank ID for deletion:", bankId); // 添加日志检查 bankId 是否正确
  this.selectedBankId = bankId;
  this.alertTitle = '确认删除';
  this.alertMessage = '你确定要删除这个银行账户吗？';
  this.alertStatus = 'warning';
  this.isConfirmation = true;
  this.showAlert = true;
},

async deleteBank() {
  console.log('Attempting to delete bank account:', this.selectedBankId); // 调试日志
  try {
    this.alertStatus = 'loading'; // 显示加载状态
    this.showAlert = true; // 确保弹窗保持打开以显示加载状态
    console.log('Sending delete request to API');
    await axios.delete(`/api/banks/${this.selectedBankId}/`);
    
    console.log('Delete request successful');
    this.banks = this.banks.filter(bank => bank.id !== this.selectedBankId);
    
    // 删除成功后的提示
    this.alertTitle = '删除成功';
    this.alertMessage = '银行账户删除成功！';
    this.alertStatus = 'success';
    this.isConfirmation = false;
  } catch (error) {
    // 删除失败处理
    console.error('Failed to delete bank account:', error);
    this.alertTitle = '删除失败';
    this.alertMessage = '删除银行账户失败。请重试。';
    this.alertStatus = 'error';
    this.isConfirmation = false;
  }
},
    handleModalClose() {
      this.showAlert = false;
    },
  },
  created() {
    this.fetchBanks();
  },
};
</script>

<style scoped>
/* 样式保持不变 */
.add-bank-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.add-bank-account-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9fafb;
  border-radius: 12px;
  padding: 15px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  width: 80%;
}

.add-icon {
  font-size: 48px;
  color: #4a90e2;
  margin-bottom: 5px;
}

.add-text {
  font-size: 12px;
  color: #333333;
  font-weight: 900;
}

.bank-account-container {
  padding-top: 20px;
}

.bank-account-box {
  display: flex;
  align-items: center;
  font-size: 12px;
  background: linear-gradient(90deg, #FF512F 0%, #F09819 100%);
  color: #dddddd;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  margin: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border-left: 8px solid #ffb6a8;
  justify-content: space-between;
}

.bank-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50px;
}

.bank-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bank-name {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 5px;
}

.bank-holder-name {
  margin-bottom: 5px;
}

.bank-account-number {
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 5px;
}
</style>

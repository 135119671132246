<template>
  <div class="register-page">
    <!-- 页面结构保持不变 -->
    <div class="promo-banner">
      <img src="@/assets/jacky-wu.png" alt="Promo Banner" />
    </div>
    <div class="promo-info">
      <!-- 页面结构保持不变 -->
      <div class="promo-item">
        <el-icon style="font-size: 20px;"><Discount /></el-icon>
        <span>100% Welcome Bonus Up to MYR 300</span>
      </div>
      <div class="promo-item">
        <el-icon style="font-size: 20px;"><Document /></el-icon>
        <span>1% Unlimited Daily Cash Rebate</span>
      </div>
      <div class="promo-item">
        <el-icon style="font-size: 20px;"><Setting /></el-icon>
        <span>Up to MYR 10,000 VIP Weekly Rescue Bonus</span>
      </div>
    </div>
    <div class="register-form">
      <div class="button-group">
        <button class="tab-button left" @click="goToLogin">登录</button>
        <button class="tab-button right active">注册</button>
      </div>
      <div class="form-section">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item prop="username">
            <label class="required">用户名</label>
            <el-input v-model="form.username" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <label class="required">密码</label>
            <el-input v-model="form.password" type="password" placeholder="密码" show-password></el-input>
          </el-form-item>
          <el-form-item prop="confirmPassword">
            <label class="required">确认密码</label>
            <el-input v-model="form.confirmPassword" type="password" placeholder="确认密码" show-password></el-input>
          </el-form-item>
          <el-form-item prop="fullName">
            <label class="required">全名</label>
            <el-input v-model="form.fullName" placeholder="全名"></el-input>
          </el-form-item>
          <el-form-item>
            <label>推荐人</label>
            <el-input
              v-model="form.referral"
              placeholder="推荐人"
              :disabled="isReferralDisabled" 
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="submit-button"
              @click="handleRegister"
              :loading="isSubmitting"
              :disabled="isDisabled"
            >
              注册
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer-info">
      <p>
        我确认我已年满18岁，并且已阅读并接受您的
        <el-link type="primary" underline>条款与条件</el-link>。
      </p>
    </div>
    
    <!-- Alert Modal -->
    <alert-modal 
      :title="modalTitle" 
      :message="modalMessage" 
      :status="modalStatus" 
      :visible="isModalVisible" 
      @close="closeModal" 
    />
  </div>
</template>

<script>
import axios from '@/axios';
import AlertModal from '@/components/AlertModal.vue';  // 确保引入AlertModal组件

export default {
  data() {
    return {
      form: {
        username: '',
        password: '',
        confirmPassword: '',
        fullName: '',
        referral: '',
      },
      isReferralDisabled: false,
      isSubmitting: false,
      isModalVisible: false,
      modalTitle: '',
      modalMessage: '',
      modalStatus: '',  // success, failed, etc.
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 6, max: 20, message: '用户名长度应为 6 到 20 个字符', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9]+$/, message: '用户名只能包含字母和数字', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 8, message: '密码长度不能少于 8 个字符', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)[a-zA-Z0-9]+$/, message: '密码不能仅包含数字', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error('两次输入的密码不一致'));
              } else {
                callback();
              }
            }, trigger: 'blur' }
        ],
        fullName: [
          { required: true, message: '请输入全名', trigger: 'blur' },
        ]
      }
    };
  },
  components: {
    AlertModal,  // 注册AlertModal组件
  },
  computed: {
    isDisabled() {
      return (
        !this.form.username ||
        !this.form.password ||
        !this.form.confirmPassword ||
        !this.form.fullName ||
        this.form.password !== this.form.confirmPassword
      );
    },
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: 'Login' });
    },
    async handleRegister() {
      if (this.isSubmitting) return;

      this.$refs.form.validate(async valid => {
        if (!valid) return;

        this.isSubmitting = true;
        const payload = {
          username: this.form.username,
          password: this.form.password,
          confirm_password: this.form.confirmPassword,
          full_name: this.form.fullName,
          referral: this.form.referral || null,
        };

        try {
          await axios.post('/v1/register/', payload);
          this.showModal('注册成功！', '您的账户已成功创建。', 'success');
        } catch (error) {
          this.showModal('注册失败', error.response.data.detail || '注册失败，请检查输入信息。', 'failed');
        } finally {
          this.isSubmitting = false;
        }
      });
    },
    showModal(title, message, status) {
      this.modalTitle = title;
      this.modalMessage = message;
      this.modalStatus = status;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      if (this.modalStatus === 'success') {
        this.$router.push({ name: 'Login' });
      }
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get('referral');
    if (referralCode) {
      this.form.referral = referralCode;
      this.isReferralDisabled = true;
    }
  },
};
</script>

<style scoped>
/* 样式部分保持不变 */
.register-page {
  padding: 20px;
  text-align: center;
}

.promo-banner img {
  max-width: 100%;
  border-radius: 12px;
}

.promo-info {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 12px;
  background: linear-gradient(90deg, #f8f8f8, #e1daff);
  border: 2px dashed #4f3f97;
}

.promo-item {
  text-align: center;
  flex: 1;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promo-item span {
  margin-top: 10px;
  font-size: 8px;
  color: #666666;
  font-weight: 700;
}

.button-group {
  display: flex;
  margin: 20px 0;
  border-radius: 24px;
  overflow: hidden;
}

.tab-button {
  padding: 10px 30px;
  border: none;
  background-color: #f0f0f0;
  font-size: 16px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  flex: 1;
}

.tab-button.left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-button.right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tab-button.active {
  background: linear-gradient(135deg, #007bff, #4f3f97);
  color: #fff;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

.form-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.submit-button {
  background: linear-gradient(135deg, #4f3f97, #007bff);
  color: #fff;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  width: 100%;
  padding: 12px 0;
  font-size: 18px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.submit-button[disabled] {
  background: #cccccc;
}

.submit-button:not([disabled]):hover {
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

.footer-info {
  margin-top: 20px;
  font-size: 12px;
  color: #999999;
}

.required:after {
  content: '*';
  color: red;
  margin-left: 2px;
}
</style>

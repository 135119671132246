// src/banks.js
export const banks = [
  { shortName: 'Maybank', fullName: 'Malayan Banking Berhad', logo: require('@/assets/maybank.webp'), lengths: [12, 14] },
  { shortName: 'CIMB', fullName: 'CIMB Bank Berhad', logo: require('@/assets/cimb.png'), lengths: [10, 12] },
  { shortName: 'PBB', fullName: 'Public Bank Berhad', logo: require('@/assets/maybank.webp'), lengths: [10, 12] },
  { shortName: 'RHB', fullName: 'RHB Bank Berhad', logo: require('@/assets/maybank.webp'), lengths: [14, 16] },
  { shortName: 'TNG', fullName: 'Touch \'n Go', logo: require('@/assets/maybank.webp'), lengths: [10, 12] },
  { shortName: 'HLB', fullName: 'Hong Leong Bank Berhad', logo: require('@/assets/maybank.webp'), lengths: [12, 14] },
  { shortName: 'AFB', fullName: 'Alliance Bank Malaysia Berhad', logo: require('@/assets/logo.png'), lengths: [14, 16] },
  { shortName: 'AGRO', fullName: 'Agrobank', logo: require('@/assets/logo.png'), lengths: [10, 12] },
  { shortName: 'AMB', fullName: 'Ambank Berhad', logo: require('@/assets/logo.png'), lengths: [12, 14] },
  { shortName: 'BSN', fullName: 'Bank Simpanan Nasional', logo: require('@/assets/logo.png'), lengths: [12, 14] },
  { shortName: 'GXB', fullName: 'Bank of China', logo: require('@/assets/logo.png'), lengths: [10, 12] },
  { shortName: 'HSBC', fullName: 'HSBC Bank Malaysia Berhad', logo: require('@/assets/logo.png'), lengths: [12, 14] },
  { shortName: 'OCBC', fullName: 'OCBC Bank', logo: require('@/assets/logo.png'), lengths: [10, 12] },
  { shortName: 'UOB', fullName: 'United Overseas Bank', logo: require('@/assets/logo.png'), lengths: [10, 12] },
  { shortName: 'STANDC', fullName: 'Standard Chartered Bank', logo: require('@/assets/logo.png'), lengths: [14, 16] },
  { shortName: 'CITI', fullName: 'Citibank Berhad', logo: require('@/assets/logo.png'), lengths: [12, 14] },
];
<template>
  <div>
    <el-carousel :interval="5000" arrow="always" height="200px">
      <el-carousel-item v-for="(item, index) in banners" :key="index">
        <a :href="item.url" target="_blank">
          <div class="slider-item" :style="{ backgroundImage: `url(${item.image_url})` }">
          </div>
        </a>
      </el-carousel-item>
    </el-carousel>
    <div class="notice-container">
      <el-icon class="fixed-icon"><Bell /></el-icon>
      <div class="notice-content">
        <span class="notice-text">{{ noticeText }}</span>
      </div>
    </div>
    <div class="button-container" v-if="!isAuthenticated">
      <router-link to="/login" class="login-button">Login</router-link>
      <router-link to="/register" class="join-button">Register</router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('app', ['banners', 'noticeText']),
    ...mapGetters('auth', ['isAuthenticated']),
  },
  created() {
    this.fetchBanners();  // 直接获取横幅数据
    this.fetchNotice();  // 直接获取公告文本
  },
  methods: {
    ...mapActions('app', ['fetchBanners', 'fetchNotice']),
  },
};
</script>

<style scoped>
.slider-item {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100% - 20px); 
  border-radius: 10px;
  margin: 10px;
}

.notice-container {
  width: calc(100% - 20px); 
  background-color: #c9c9c9;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 5px 0;
  border-radius: 10px 10px 0 0;
  margin: 0 10px; 
}

.fixed-icon {
  color: #fff;
  padding: 0 10px;
}

.notice-content {
  flex: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%);
}

.notice-text {
  font-size: 12px;
  color: #fff;
  animation: scroll-left 10s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 0 10px;
}

.login-button, .join-button {
  width: 50%;
  height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none; /* 移除默认的下划线 */
  color: white;
}

.login-button {
  background-color: #f5a623;
  border-radius: 0 0 0 5px;
}

.join-button {
  background-color: #007bff;
  border-radius: 0 0 5px 0;
}

.login-button:hover, .join-button:hover {
  opacity: 0.8; /* 提供一个简单的hover效果 */
}
</style>

<!-- components/Loading.vue -->
<template>
    <div v-if="visible" class="loading-overlay">
      <el-icon class="loading-icon">
        <Loading />
      </el-icon>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import { Loading } from '@element-plus/icons-vue';
  
  export default {
    computed: {
      ...mapState(['isLoading']),
      visible() {
        return this.isLoading;
      },
    },
    components: {
      Loading,
    },
  };
  </script>
  
  <style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-icon {
    font-size: 50px;
    color: #ffffff;
  }
  </style>
  
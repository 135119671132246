<template>
  <div class="container">
    <el-form>
      <el-form-item class="form-label" label="Bank">
        <div class="bank-flex-container">
          <div
            v-for="(bank, index) in banks"
            :key="index"
            class="bank-item"
            :class="{ selected: selectedBank === bank.shortName }"
            @click="selectBank(bank.shortName)"
          >
            <div class="bank-logo-wrapper">
              <img :src="bank.logo" :alt="bank.shortName" class="bank-logo" />
            </div>
            <span class="bank-name">{{ bank.shortName }}</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item class="form-label" label="Bank Holder Name">
        <el-input v-model="bankHolderName" disabled></el-input>
      </el-form-item>
      <el-form-item class="form-label" label="Bank Account No">
        <el-input v-model="form.bankAccountNo" @input="trimAccountNumber"></el-input>
      </el-form-item>
      <el-form-item>
        <!-- 先调用 confirmBankDetails 来显示确认弹窗 -->
        <el-button class="btn-button" @click="confirmBankDetails">添加银行</el-button>
      </el-form-item>
    </el-form>

    <AlertModal
      :title="alertTitle"
      :message="alertMessage"
      :status="alertStatus"
      :visible="showAlert"
      :isConfirmation="alertStatus === 'confirmation'"
      @confirm="confirmAddBank" 
      @close="handleModalClose" 
      @update:visible="showAlert = $event"
    />
  </div>
</template>

<script>
import axios from '@/axios';
import { banks } from '@/banks.js';
import AlertModal from '@/components/AlertModal.vue';

export default {
  components: {
    AlertModal,
  },
  data() {
    return {
      banks,
      selectedBank: 'Maybank', // 默认选择的银行简称
      form: {
        bankAccountNo: '',
      },
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      alertStatus: 'confirmation', // 默认为 'confirmation' 状态
    };
  },
  computed: {
    bankHolderName() {
      return this.$store.getters['profile/userProfile'].full_name || '';
    }
  },
  methods: {
    selectBank(bankShortName) {
      this.selectedBank = bankShortName;
    },
    trimAccountNumber() {
      this.form.bankAccountNo = this.form.bankAccountNo.replace(/\s/g, '');
    },
    confirmBankDetails() {
      // 设置确认信息
      this.alertTitle = '确认添加';
      this.alertMessage = `${this.selectedBank} ${this.form.bankAccountNo}`;
      this.alertStatus = 'confirmation';
      this.showAlert = true; // 显示确认弹框
    },
    async confirmAddBank() {
      try {
        const data = {
          bank_branch: this.selectedBank,
          bank_holder_name: this.bankHolderName,
          bank_account_number: this.form.bankAccountNo,
        };
        await axios.post('/api/banks/', data);

        // 设置成功信息
        this.alertTitle = '成功';
        this.alertMessage = '银行账户添加成功。';
        this.alertStatus = 'success';
        this.showAlert = true; // 显示成功提示弹框
      } catch (error) {
        console.error('Error adding bank:', error);

        // 设置失败信息
        this.alertTitle = '错误';
        this.alertMessage = '无法添加银行账户。请重试。';
        this.alertStatus = 'error'; // 确保设置为 'error' 状态
        this.showAlert = true; // 显示错误提示弹框
      }
    },
    handleModalClose() {
      if (this.alertStatus === 'success') {
        this.$router.go(-1); // 成功后返回上一页
      }
      this.showAlert = false; // 仅关闭弹框，不执行任何操作
    },
  },
  created() {
    this.$store.dispatch('profile/fetchUserProfile').catch((error) => {
      console.error('Failed to load user profile:', error);
    });
  },
};
</script>

<style scoped>
.bank-label {
  margin-bottom: 10px;
}

.bank-flex-container {
  display: flex;
  flex-wrap: wrap;
}

.bank-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 5px;
  border: none;
  border-radius: 8px;
  transition: transform 0.2s ease, border-color 0.2s ease;
}

.bank-item.selected .bank-logo-wrapper {
  border-color: #f09819;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.bank-logo-wrapper {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.bank-logo {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  filter: grayscale(100%);
}

.bank-item.selected .bank-logo {
  filter: none;
}

.bank-name {
  font-size: 11px;
  font-weight: 700;
  text-align: center;
}
</style>

<template>
  <div v-if="visible" class="alert-modal" @click.self="close">
    <div :class="['modal-content', status]">
      <!-- Close Button in the Top Right -->
      <span class="close" @click="close">&times;</span>
    
      <div class="modal-icon">
        <el-icon v-if="status === 'success'" class="icon-success">
          <CircleCheckFilled />
        </el-icon>
        <el-icon v-else-if="status === 'failed' || status === 'error'" class="icon-failed">
          <CircleCloseFilled />
        </el-icon>
        <el-icon v-else-if="status === 'interact'" class="icon-interact">
          <CircleCloseFilled />
        </el-icon>
        <el-icon v-else-if="status === 'confirmation'" class="icon-confirmation">
          <CircleCheckFilled />
        </el-icon>
        <el-icon v-else-if="status === 'warning'" class="icon-warning">
          <WarningFilled />
        </el-icon>
      </div>
      
      <div class="modal-message">
        <h3>{{ title }}</h3>
        <p v-html="message"></p>
      </div>
      
      <div class="modal-footer">
        <!-- 根据不同状态显示不同的按钮 -->
        <a v-if="status === 'interact'" :href="botLink" target="_blank" :class="['btn-get-code', getButtonClass()]" @click="onGetCodeClick">获取验证码</a>
        <button v-if="isConfirmation && status !== 'interact'" :class="['btn-confirm', getButtonClass()]" @click="confirmAction">确认</button>
        <button v-else-if="status !== 'interact'" :class="['btn-close', getButtonClass()]" @click="close">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Alert",
    },
    message: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "success", // success, failed, interact, error, confirmation, warning
    },
    visible: {
      type: Boolean,
      default: false,
    },
    botLink: {
      type: String,
      default: "", 
    },
    isConfirmation: {
      type: Boolean,
      default: false, // 控制是否显示确认和取消按钮
    },
  },
  methods: {
    close() {
      this.$emit('close');  // 触发父组件的 handleModalClose 方法
      this.$emit('update:visible', false);
    },
    onGetCodeClick() {
      // 当用户点击 "获取验证码" 按钮时，进行一些操作
      this.$emit('update:visible', false);
      this.$emit('get-code-clicked'); // 触发一个事件，以便父组件可以处理这个操作
    },
    confirmAction() { // 确保方法名称为 confirmAction
      this.$emit('confirm');  // 触发父组件的确认删除逻辑
      this.close();  // 关闭弹窗
    },
    getButtonClass() {
      switch (this.status) {
        case 'success':
          return 'btn-success';
        case 'failed':
        case 'error':
          return 'btn-failed';
        case 'interact':
          return 'btn-interact';
        case 'confirmation':
          return 'btn-confirmation';
        case 'warning':
          return 'btn-warning';
        default:
          return 'btn-default';
      }
    }
  },
};
</script>
  
<style scoped>
.alert-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.6); /* Slight transparency for the background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: 100%;
  max-width: 400px; /* 设置最大宽度，避免按钮溢出 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}

.modal-content.success {
  border-top: 4px solid #00ff15;
}

.modal-content.failed {
  border-top: 4px solid #ff0033;
}

.modal-content.interact {
  border-top: 4px solid #ffa500;
}

.modal-content.confirmation {
  border-top: 4px solid #007bff; /* 为确认状态设置样式 */
}

.modal-content.warning {
  border-top: 4px solid #ffa500; /* 为警告状态设置样式 */
}

/* Close button */
.close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-icon {
  margin-bottom: 10px;
}

.icon-success {
  color: #00ff15;
  font-size: 50px;
}

.icon-failed {
  color: #ff0033;
  font-size: 50px;
}

.icon-interact {
  color: #ffa500;
  font-size: 50px;
}

.icon-confirmation {
  color: #007bff; /* 确认状态的图标颜色 */
  font-size: 50px;
}

.icon-warning {
  color: #ffa500; /* 警告状态的图标颜色 */
  font-size: 50px;
}

.modal-message h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.modal-message p {
  font-size: 14px;
  margin-bottom: 15px;
}

/* 动态设置按钮颜色 */
.btn-close, .btn-get-code, .btn-confirm, .btn-cancel {
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;
}

.btn-success {
  background-color: #00ff15;
}

.btn-failed {
  background-color: #ff0033;
}

.btn-interact {
  background-color: #ffa500;
}

.btn-confirmation {
  background-color: #007bff;
}

.btn-warning {
  background-color: #ffa500;
}

.btn-close:hover,
.btn-get-code:hover,
.btn-confirm:hover,
.btn-cancel:hover {
  opacity: 0.9;
}

a.btn-get-code {
  text-decoration: none;
}

.modal-message h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #000; /* 确保颜色与背景不冲突 */
}
</style>

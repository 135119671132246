<template>
    <div>
<RefferalCode />
</div>
</template>

<script>
import RefferalCode from '@/views/Referral/components/RefferalCode.vue'; // 根据你的路径调整


export default {
  components: {
    RefferalCode,
   },
};
</script>
<template>
  <div>
    <div class="safety-container">
      <div class="safety-header">
        <div class="safety-status">
          <span>
            Account Safety: 
            <span :class="getSafetyStatusClass()">
              {{ getSafetyStatusText() }}
            </span>
          </span>
        </div>
      </div>
      <div class="safety-actions">
        <!-- Date of Birth Verification -->
        <div class="icon-wrapper" @click="handleDateOfBirthClick">
          <el-icon style="font-size: 32px;">  <!-- 调整图标大小 -->
            <Dessert />
          </el-icon>
          <el-icon class="status-icon" :style="{ color: isVerified('date_of_birth') ? '#00ff15' : 'red', fontSize: '20px' }">  <!-- 调整图标大小 -->
            <CircleCheckFilled v-if="isVerified('date_of_birth')" />
            <CircleCloseFilled v-else />
          </el-icon>
        </div>
        <div class="divider"></div>

        <!-- Bank Account Verification -->
        <div class="icon-wrapper" @click="handleBankAccountClick">
          <el-icon style="font-size: 32px;">  <!-- 调整图标大小 -->
            <CreditCard />
          </el-icon>
          <el-icon class="status-icon" :style="{ color: isVerified('bank_account') ? '#00ff15' : 'red', fontSize: '20px' }">  <!-- 调整图标大小 -->
            <CircleCheckFilled v-if="isVerified('bank_account')" />
            <CircleCloseFilled v-else />
          </el-icon>
        </div>
        <div class="divider"></div>

        <!-- Phone Number Verification -->
        <div class="icon-wrapper" @click="handlePhoneNoClick">
          <el-icon style="font-size: 32px;">  <!-- 调整图标大小 -->
            <Cellphone />
          </el-icon>
          <el-icon class="status-icon" :style="{ color: isVerified('phone_verified') ? '#00ff15' : 'red', fontSize: '20px' }">  <!-- 调整图标大小 -->
            <CircleCheckFilled v-if="isVerified('phone_verified')" />
            <CircleCloseFilled v-else />
          </el-icon>
        </div>
        <div class="divider"></div>

        <!-- Email Verification -->
        <div class="icon-wrapper" @click="handleEmailClick">
          <el-icon style="font-size: 32px;">  <!-- 调整图标大小 -->
            <Postcard />
          </el-icon>
          <el-icon class="status-icon" :style="{ color: isVerified('email_verified') ? '#00ff15' : 'red', fontSize: '20px' }">  <!-- 调整图标大小 -->
            <CircleCheckFilled v-if="isVerified('email_verified')" />
            <CircleCloseFilled v-else />
          </el-icon>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <!-- Date of Birth Modal -->
    <div v-if="showBirthdayModal" class="custom-modal" @click.self="closeBirthdayModal">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Add Date of Birth</span>
          <span class="close" @click="closeBirthdayModal">&times;</span>
        </div>
        <div class="modal-body">
          <el-date-picker
            v-model="birthday"
            type="date"
            placeholder="Pick a date"
            :default-value="defaultBirthday"
            format="MMMM DD, YYYY"
            class="date-picker"
          />
        </div>
        <div class="modal-footer">
          <button class="btn-submit" @click="submitBirthday">Submit</button>
        </div>
      </div>
    </div>
    
    <!-- Phone Number Modal -->
    <div v-if="showContactModal" class="custom-modal" @click.self="closeContactModal">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Contact No Verification</span>
          <span class="close" @click="closeContactModal">&times;</span>
        </div>
        <div class="modal-body">
          <div class="input-group">
            <div class="fixed-prefix">
              <el-icon>
                <Cellphone />
              </el-icon>
              <span class="prefix-text">+60</span>
            </div>
            <el-input v-model="contactNumber" placeholder="Phone Number" class="contact-input" />
            <el-button v-if="!showOTPInput" class="btn-send-otp" @click="preparePhoneVerification">Next</el-button>
          </div>
          <div v-if="showOTPInput" class="input-group">
            <el-input v-model="tacCode" placeholder="Enter OTP" class="tac-input" />
            <el-button class="btn-send-otp" @click="resetPhoneNumber">Modify</el-button>
          </div>
        </div>
        <div v-if="showOTPInput" class="modal-footer">
          <button class="btn-submit" @click="verifyPhoneNumber">Verify OTP</button> <!-- 修改号码按钮 -->
        </div>
      </div>
    </div>


    <!-- Email Verification Modal -->
    <div v-if="showEmailModal" class="custom-modal" @click.self="closeEmailModal">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Email Verification</span>
          <span class="close" @click="closeEmailModal">&times;</span>
        </div>
        <div class="modal-body">
          <div class="input-group">
            <el-input v-model="email" placeholder="Email" class="email-input" />
            <el-button class="btn-send-otp" @click="sendEmailTAC">Send TAC</el-button>
          </div>
          <el-input v-model="tacEmailCode" placeholder="TAC Code" class="tac-input" />
        </div>
        <div class="modal-footer">
          <button class="btn-submit" @click="submitEmail">Submit</button>
        </div>
      </div>
    </div>

    <!-- AlertModal Component -->
    <AlertModal
      :title="alertTitle"
      :message="alertMessage"
      :status="alertStatus"
      :visible="showAlert"
      :bot-link="telegramLink"
      @update:visible="showAlert = $event"
      @get-code-clicked="handleGetCodeClicked"
    />
  </div>
</template>

<script>
import AlertModal from '@/components/AlertModal.vue';
import axios from '@/axios';

export default {
  components: {
    AlertModal,
  },
  data() {
    return {
      showBirthdayModal: false,
      showContactModal: false,
      showEmailModal: false,
      showAlert: false,
      showOTPInput: false,
      alertTitle: "",
      alertMessage: "",
      alertStatus: "success",
      telegramLink: '',
      verificationStatus: {
        date_of_birth: false,
        phone_verified: false,
        email_verified: false,
        bank_account: false,
      },
      birthday: '',
      contactNumber: '',
      tacCode: '',
      email: '',
      tacEmailCode: '',
      defaultBirthday: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
    };
  },
  methods: {
    async fetchVerificationStatus() {
      try {
        const response = await axios.get('/api/verification-status/');
        this.verificationStatus = response.data;
      } catch (error) {
        this.showAlertModal("Error", "Failed to fetch verification status.", "failed");
      }
    },
    isVerified(type) {
      return this.verificationStatus[type];
    },
    getSafetyStatusClass() {
      const verifiedCount = Object.values(this.verificationStatus).filter(v => v).length;

      if (verifiedCount === 4) {
        return 'verified';
      } else if (verifiedCount >= 3) {
        return 'strong-verify';
      } else {
        return 'not-verified';
      }
    },
    getSafetyStatusText() {
      const verifiedCount = Object.values(this.verificationStatus).filter(v => v).length;

      if (verifiedCount === 4) {
        return 'Verified';
      } else if (verifiedCount >= 3) {
        return 'Strong Verify';
      } else {
        return 'Not Verified';
      }
    },
    handleDateOfBirthClick() {
      if (this.isVerified('date_of_birth')) {
        this.showAlertModal("Date of Birth Already Verified", "Your Date of Birth has been successfully verified.", "success");
      } else {
        this.showBirthdayModal = true;
      }
    },
    handlePhoneNoClick() {
      if (this.isVerified('phone_verified')) {
        this.showAlertModal("Phone Number Already Verified", "Your Phone Number has been successfully verified.", "success");
      } else {
        this.showContactModal = true;
      }
    },
    handleEmailClick() {
      if (this.isVerified('email_verified')) {
        this.showAlertModal("Email Already Verified", "Your Email has been successfully verified.", "success");
      } else {
        this.showEmailModal = true;
      }
    },
    handleBankAccountClick() {
      if (this.isVerified('bank_account')) {
        this.showAlertModal("Bank Account Already Verified", "Your Bank Account has been successfully verified.", "success");
      } else {
        this.$router.push('/add-bank');
      }
    },
    showAlertModal(title, message, status) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertStatus = status;
      this.showAlert = true;
    },
    async submitBirthday() {
      try {
        const formattedDate = new Date(this.birthday).toISOString().split('T')[0];
        await axios.post('/api/verify-dof/', { date_of_birth: formattedDate });
        this.showAlertModal("Date of Birth Added", "Your Date of Birth has been added successfully.", "success");
        this.verificationStatus.date_of_birth = true;
        this.showBirthdayModal = false;
      } catch (error) {
        this.showAlertModal("Error", "Failed to add Date of Birth.", "failed");
      }
    },
    resetPhoneNumber() {
        this.contactNumber = ''; // 清除当前输入的号码
        this.showOTPInput = false; // 隐藏OTP输入框，返回号码输入界面
        this.showAlertModal("Info", "Please enter your phone number again.", "info");
    },
    async preparePhoneVerification() {
    try {
        let completePhoneNumber = this.contactNumber.trim();

        // 将 '60' 前缀添加到用户输入的号码
        completePhoneNumber = '60' + completePhoneNumber;

        // 检查手机号是否为纯数字，并且符合马来西亚手机号格式
        const malaysiaPhoneRegex = /^60[1][0-9]{8,9}$/;

        if (!malaysiaPhoneRegex.test(completePhoneNumber)) {
            this.showAlertModal("Error", "Please enter a valid Malaysian phone number (starting with 1, followed by 9-10 digits).", "failed");
            return;
        }

        const response = await axios.post('/api/prepare-phone-verification/', { phone_no: completePhoneNumber });

        this.telegramLink = response.data.telegram_link;

        this.showAlertModal(
            "Complete Verification in Telegram",
            `Please click the "Get Code" button to interact with our Telegram Bot, then return and continue.`,
            "interact"
        );

        // 显示下一步的OTP输入框
        this.showOTPInput = true;

    } catch (error) {
        this.showAlertModal("Error", "Failed to prepare phone verification. Please try again.", "failed");
        this.showOTPInput = false;  // 如果发生错误，不显示 OTP 输入框，让用户可以修改号码
    }
},
    async verifyPhoneNumber() {
      try {
        let completePhoneNumber = this.contactNumber.trim();

        // 添加前缀 60
        completePhoneNumber = '60' + completePhoneNumber;

        const response = await axios.post('/api/verify-otp/', { phone_no: completePhoneNumber, otp_code: this.tacCode });

        if (response.data.status === "success") {
          this.showAlertModal("Phone Verified", "Your phone number has been successfully verified.", "success");

          // 更新 verificationStatus
          this.verificationStatus.phone_verified = true;

          // 隐藏 OTP 输入框和验证模态框
          this.showOTPInput = false;
          this.showContactModal = false;
        }
      } catch (error) {
        this.showAlertModal("Error", "Failed to verify phone number. Please check the OTP code and try again.", "failed");
      }
    },
    async submitContact() {
      try {
        let completePhoneNumber = this.contactNumber.trim();

        // 添加前缀 60
        completePhoneNumber = '60' + completePhoneNumber;

        await axios.post('/api/send-phone-otp/', { phone_no: completePhoneNumber });
        this.showAlertModal("OTP Sent", "Your OTP has been sent via Telegram. Please check your Telegram.", "success");
      } catch (error) {
        this.showAlertModal("Error", "Failed to send OTP. Please try again.", "failed");
      }
    },
    async sendEmailTAC() {
      try {
        if (!this.validateEmail(this.email)) {
          this.showAlertModal('Error', 'Invalid email format.', 'failed');
          return;
        }

        await axios.post('/api/send-email-tac/', { email: this.email });
        this.showAlertModal("TAC Sent", "TAC has been sent to your email. Please check your inbox.", "success");
      } catch (error) {
        this.showAlertModal("Error", "Failed to send TAC. Please try again.", "failed");
      }
    },
    async submitEmail() {
      try {
        await axios.post('/api/verify-email/', { email: this.email, tac_code: this.tacEmailCode });
        this.showAlertModal("Email Verified", "Your Email has been verified successfully.", "success");
        this.verificationStatus.email_verified = true;
        this.showEmailModal = false;
      } catch (error) {
        this.showAlertModal("Error", "Failed to verify Email. Please check the TAC code and try again.", "failed");
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    closeBirthdayModal() {
      this.showBirthdayModal = false;
    },
    closeContactModal() {
      this.showContactModal = false;
    },
    closeEmailModal() {
      this.showEmailModal = false;
    },
    handleGetCodeClicked() {
      console.log("User clicked Get Code and interacted with Telegram Bot");
    }
  },
  mounted() {
    this.fetchVerificationStatus();
  }
};
</script>

<style scoped>
.safety-container {
  background-color: #fafbf9;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 10px;
  margin: 0 10px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
}

.safety-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  width: 100%;
}

.safety-status {
  font-size: 16px;  /* 调整状态文本的字体大小 */
  color: #333;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.verified {
  font-weight: bold;
  color: #00ff15;
}

.strong-verify {
  font-weight: bold;
  color: #ffa500; /* 黄色警告颜色 */
}

.not-verified {
  font-weight: bold;
  color: red;
}

.safety-actions {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.icon-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.status-icon {
  position: absolute;
  top: -8px;
  right: -10px;
}

.divider {
  width: 1px;
  height: 40px;
  background-color: #d9d9d9;
  margin: 0 10px;
}

.custom-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 340px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1050;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.modal-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.close {
  cursor: pointer;
  font-size: 20px;
}

.modal-body {
  margin: 15px 0;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.date-picker .el-input__inner {
  width: 100% !important;
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
}

.fixed-prefix {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 6px 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #d9d9d9;
  border-right: none;
}

.fixed-prefix .el-icon {
  font-size: 18px;
  color: #007bff;
}

.prefix-text {
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #007bff;
}

::v-deep .el-date-editor--date {
  width: 100% !important;
}

.contact-input .el-input__inner {
  height: 40px;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
  border-left: none;
}

.tac-input .el-input__inner {
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
}

.btn-send-otp {
  background-color: #007bff;
  margin-left: 5px;
  color: #fff;
  border: none;
  height: 30px;
  border-radius: 4px;
  padding: 0 15px;
  cursor: pointer;
  font-size: 14px;
}

.btn-send-otp:hover {
  background-color: #0056b3;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.btn-submit {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
}

.btn-submit:hover {
  background-color: #0056b3;
}
</style>
<template>
  <div class="container">
    <div class="filter-section">
      <div class="date-filter">
        <el-date-picker
          v-model="dateStart"
          type="date"
          placeholder="开始日期"
          class="date-picker"
        ></el-date-picker>
        <span class="to-text">到</span>
        <el-date-picker
          v-model="dateEnd"
          type="date"
          placeholder="结束日期"
          class="date-picker"
        ></el-date-picker>
      </div>
      <div class="quick-buttons">
        <el-button type="primary" plain @click="setQuickDate('today')">今天</el-button>
        <el-button type="primary" plain @click="setQuickDate('3days')">3天内</el-button>
        <el-button type="primary" plain @click="setQuickDate('week')">1周内</el-button>
        <el-button type="primary" plain @click="setQuickDate('month')">1个月内</el-button>
      </div>
      <el-button type="primary" class="search-button" @click="search">
        查询
      </el-button>
      <el-divider></el-divider>
      <h2>History</h2>
    </div>
    <div v-if="records.length > 0" class="record-section">
      <el-table :data="records" style="width: 100%" border size="small">
        <el-table-column prop="activity_name" label="红包活动" align="center"></el-table-column>
        <el-table-column prop="red_packet_amount" label="金额" width="50" align="center"></el-table-column>
        <el-table-column prop="game_name" label="目标游戏" align="center"></el-table-column>
        <el-table-column prop="created_at" label="提交时间" width="100" align="center">
          <template v-slot="scope">
            <div>
              <div>{{ formatDate(scope.row.created_at) }}</div>
              <div class="time">{{ formatTime(scope.row.created_at) }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template v-slot="scope">
            <el-tag :type="getStatusTagType(scope.row.status)">
              {{ getStatusLabel(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else class="no-record-section">
      <p>没有找到记录</p>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';

export default {
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      records: [],
      totalRecords: 0,
    };
  },
  created() {
    this.fetchRecords();  // 调用时获取所有记录，不限制条数
  },
  methods: {
    fetchRecords() {
      axios.get('/api/user-records/')  // 删除 'latest: 10' 参数
        .then(response => {
          this.records = response.data || [];
          this.totalRecords = response.data.length;
        })
        .catch(error => {
          console.error('Error fetching records:', error);
          this.records = [];
        });
    },
    search() {
      const params = {
        date_start: this.dateStart,
        date_end: this.dateEnd,
      };
      axios.get('/api/user-records/', { params })
        .then(response => {
          this.records = response.data || [];
          this.totalRecords = response.data.length;
        })
        .catch(error => {
          console.error('Error fetching records:', error);
          this.records = [];
        });
    },
    getStatusLabel(status) {
      const statusLabels = {
        pending: '待处理',
        approved: '已确认',
        rejected: '已拒绝',
      };
      return statusLabels[status] || '未知';
    },
    getStatusTagType(status) {
      const tagTypes = {
        pending: 'warning',
        approved: 'success',
        rejected: 'danger',
      };
      return tagTypes[status] || 'info';
    },
    setQuickDate(type) {
      const today = new Date();
      const startOfDay = new Date(today.setHours(0, 0, 0, 0));
      const endOfDay = new Date(today.setHours(23, 59, 59, 999));

      switch (type) {
        case 'today':
          this.dateStart = startOfDay;
          this.dateEnd = endOfDay;
          break;
        case '3days':
          this.dateStart = new Date(today.setDate(today.getDate() - 3));
          this.dateEnd = endOfDay;
          break;
        case 'week':
          this.dateStart = new Date(today.setDate(today.getDate() - 7));
          this.dateEnd = endOfDay;
          break;
        case 'month':
          this.dateStart = new Date(today.setMonth(today.getMonth() - 1));
          this.dateEnd = endOfDay;
          break;
      }
      this.search();
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    formatTime(date) {
      return new Date(date).toLocaleTimeString();
    },
  }
};
</script>

<style scoped>
.container {
  padding: 15px;
}

.filter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.date-filter {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.quick-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.date-picker {
  flex: 1;
  margin: 0 5px;
}

.to-text {
  margin: 0 5px;
  font-weight: bold;
  color: #333;
}

.search-button {
  margin-top: 10px;
  width: 100%;
  background-color: #00796b;
  color: white;
}

.record-section {
  margin-top: 20px;
  width: 100%;
}

.el-table th, .el-table td {
  padding: 8px 10px;
  font-size: 14px;
}

.el-table th {
  background-color: #f3f3f3;
  color: #333;
}

.el-tag {
  padding: 3px 8px;
  font-size: 12px;
}

.time {
  font-size: 12px;
  color: #888;
}

.no-record-section {
  text-align: center;
  font-size: 18px;
  color: #666;
}
</style>

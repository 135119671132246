<template>
  <div class="inbox-container">
    <div v-if="notifications.length" class="notification-list">
      <el-card
        v-for="(notification, index) in notifications"
        :key="index"
        class="notification-item"
      >
        <div class="notification-content">
          <el-icon :size="24">
            <component :is="notification.icon" />
          </el-icon>
          <div class="details">
            <p class="title">{{ notification.title }}</p>
            <p class="description">{{ notification.description }}</p>
            <p class="time">{{ notification.time }}</p>
          </div>
        </div>
      </el-card>
    </div>
    <div v-else class="no-notifications">
      无任何通知
    </div>
  </div>
</template>

<script>
import { WalletFilled, GoodsFilled } from '@element-plus/icons-vue';

export default {
  data() {
    return {
      notifications: [
        {
          icon: WalletFilled,
          title: "交易通知",
          description: "HLB Connect: 第三方转账 RM1,500.00 到 SCREEN ARMOUR ENTE...",
          time: "13 Aug 2024 12:36PM",
        },
        {
          icon: WalletFilled,
          title: "交易通知",
          description: "HLB Connect: 第三方转账 RM1,500.00 到 SCREEN ARMOUR ENTE...",
          time: "07 Aug 2024 01:18PM",
        },
        {
          icon: GoodsFilled,
          title: "交易通知",
          description: "HLB: 您已收到一笔 RM2500.00 的转账...",
          time: "05 Aug 2024 10:18AM",
        },
        // 可以根据实际需求添加更多通知记录
      ],
    };
  },
};
</script>

<style scoped>
.inbox-container {
  padding: 5px;
  margin: 0 auto;
}

.notification-item {
  margin-bottom: 5px; /* 减少卡片之间的空白 */
  background-color: #fff;
  border-radius: 5px; /* 减小边框圆角 */
  border-left: 5px solid #F09819;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* 调整阴影以增强紧凑感 */
}

.notification-content {
  display: flex;
  align-items: center;
}

.details {
  flex-grow: 1;
  margin-left: 10px; /* 减少图标与文本的间距 */
}

.title {
  font-weight: bold;
  color: #F09819;
  margin-bottom: 2px; /* 减少标题和描述之间的间距 */
}

.description {
  font-size: 12px;
  color: #666;
  margin-bottom: 2px; /* 减少描述和时间之间的间距 */
}

.time {
  font-size: 10px;
  color: #999;
}

.no-notifications {
  padding: 10px;
  margin: 0 auto;
  color: #666;
  font-size: 14px;
  text-align: center;
}
</style>

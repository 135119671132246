<template>
  <div class="promotion-card">
    <img :src="imageSrc" alt="Promotion Image" class="promo-image" />
    <div class="promo-content">
      <h3>{{ title }}</h3>
      <p>{{ description }}</p>
      <div class="promo-actions">
        <button class="info-btn" @click="showDetails">More Info</button>
        <!-- Hide Join Now button if the user is logged in -->
        <button v-if="!isAuthenticated" class="join-btn">Join Now</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated' // Assuming your Vuex module is named 'auth'
    })
  },
  methods: {
    showDetails() {
      this.$router.push({ name: 'PromotionDetail', params: { id: this.id } });
    }
  }
}
</script>

<style scoped>
.promotion-card {
  background-color: #1a1a2e;
  border: 1px solid #ccc;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 10px;
  color: #ffffff;
}

.promo-image {
  width: 100%;
  height: auto;
}

.promo-content {
  padding: 15px;
  text-align: center;
}

.promo-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.info-btn, .join-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.info-btn {
  background-color: #007bff;
  color: white;
}

.join-btn {
  background-color: #ff9800;
  color: white;
}
</style>

<template>
  <div class="bottom-nav">
    <div
      class="nav-item"
      :class="{ active: isActive('Home') }"
      @click="goTo('Home')"
    >
      <el-icon style="font-size: 24px;"><HomeFilled /></el-icon>
      <span>Home</span>
    </div>
    <div
      class="nav-item"
      :class="{ active: isActive('History') }"
      @click="goTo('History')"
    >
      <el-icon style="font-size: 24px;"><Document /></el-icon>
      <span>History</span>
    </div>
    <div
      class="nav-item"
      :class="{ active: isActive('Referrals') }"
      @click="goTo('Referrals')"
    >
      <el-icon style="font-size: 24px;"><Document /></el-icon>
      <span>Referrals</span>
    </div>
    <div
      class="nav-item"
      :class="{ active: isActive('Promotions') }"
      @click="goTo('Promotions')"
    >
      <el-icon style="font-size: 24px;"><Discount /></el-icon>
      <span>Promo</span>
    </div>
    <div
      v-if="!isLoggedIn"
      class="nav-item"
      :class="{ active: isActive('Login') }"
      @click="goTo('Login')"
    >
      <el-icon style="font-size: 24px;"><SwitchButton /></el-icon>
      <span>Login</span>
    </div>
    <div
      v-if="isLoggedIn"
      class="nav-item"
      :class="{ active: isActive('LiveChat') }"
      @click="startLiveChat"
    >
      <el-icon style="font-size: 24px;"><Message /></el-icon>
      <span>Live Chat</span>
    </div>
    <div
      class="nav-item"
      :class="{ active: isActive('Account') }"
      @click="goTo('Account')"
    >
      <el-icon style="font-size: 24px;"><Setting /></el-icon>
      <span>Account</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isLoggedIn() {
      return !!localStorage.getItem('token');
    }
  },
  methods: {
    goTo(page) {
      this.$router.push({ name: page });
    },
    isActive(page) {
      return this.currentRouteName === page;
    },
    startLiveChat() {
      alert("Live Chat started!");
    },
    updateNav() {
      if (this.$el) {
        this.$forceUpdate();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.updateNav();
    });
  },
  watch: {
    isLoggedIn() {
      this.updateNav();
    }
  }
};
</script>

<style scoped>
.bottom-nav {
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(135deg, #f0f0f0, #f8f7ee);
  border-top: 1px solid #ddd;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  height: 60px; /* 明确设置高度 */
  position: fixed;
  bottom: 0;
  width: 100%; /* 保持100%宽度 */
  z-index: 1000;
  -webkit-overflow-scrolling: touch; /* iOS滚动优化 */
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  transition: transform 0.3s, color 0.3s;
}

.nav-item.active {
  color: #b80000;
}

.nav-item:hover {
  transform: translateY(-5px);
  color: #b80000;
}

.el-icon {
  padding: 5px;
  color: inherit;
}
</style>

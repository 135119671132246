<template>
  <div class="latest-promo">
    <div class="promo-header">
      <h2>Promos & Rewards</h2>
      <el-button class="view-all-button" @click="goToPromotionsPage">See all</el-button>
    </div>
    <swiper :slides-per-view="1.2" :space-between="10" class="promo-slider">
      <swiper-slide v-for="(promo, index) in displayedPromos" :key="index">
        <div class="promo-card">
          <img :src="promo.image" alt="promo" class="promo-image" />
          <div class="promo-content">
            <div class="title-container">
              <h3>{{ promo.name }}</h3>
            </div>
            <div class="timer-button-container">
              <div class="timer">
                <div class="timer-unit">
                  <div class="time-box">{{ countdown(promo.id)?.days }}</div>
                  <span class="unit">Days</span>
                </div>
                <div class="timer-unit">
                  <div class="time-box">{{ countdown(promo.id)?.hours }}</div>
                  <span class="unit">Hour</span>
                </div>
                <div class="timer-unit">
                  <div class="time-box">: {{ countdown(promo.id)?.minutes }}</div>
                  <span class="unit">Min</span>
                </div>
                <div class="timer-unit">
                  <div class="time-box">: {{ countdown(promo.id)?.seconds }}</div>
                  <span class="unit">Sec</span>
                </div>
              </div>
              <el-button class="info-button" @click="goToPromotion(promo.id)">More Info</el-button>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState('app', ['promotions']),
    ...mapGetters('app', ['countdowns']),
    displayedPromos() {
      return Array.isArray(this.promotions) ? this.promotions.slice(0, 5) : [];
    },
    countdown() {
      return (id) => this.countdowns(id);
    },
  },
  mounted() {
    this.fetchPromotions();
  },
  beforeUnmount() {
    this.clearIntervals();
  },
  methods: {
    ...mapActions('app', ['fetchPromotions', 'clearIntervals']),
    goToPromotion(id) {
      this.$router.push({ name: 'PromotionDetail', params: { id } });
    },
    goToPromotionsPage() {
      this.$router.push({ name: 'Promotions' });
    },
  },
};
</script>

<style scoped>
.latest-promo {
  color: #333;
  padding: 10px;
}

.promo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.promo-slider {
  margin-top: 10px;
}

.promo-card {
  position: relative;
  background-color: #1a1a2e;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 300px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.promo-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.promo-content {
  padding: 10px 15px;
}

.title-container {
  padding: 5px;
  text-align: left;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
}

.timer-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timer {
  display: flex;
  align-items: flex-start; /* Align the items at the top */
}

.timer-unit {
  display: flex;
  flex-direction: column; /* Arrange time-box and unit in a column */
  align-items: center; /* Center align them */
  margin: 0 2px; /* Space between units */
}

.time-box {
  background-color: #fff;
  color: #007bff;
  padding: 2px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 40px;
  box-sizing: border-box;
}

.unit {
  font-size: 10px;
  color: #b4b4b4;
  margin-top: 2px; /* Add a small margin above the unit label */
  text-align: center; /* Center align the unit text */
}

.info-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.info-button:hover {
  background-color: #3697ff;
  color: #fff;
}

.view-all-button {
  background-color: transparent;
  color: #007bff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.view-all-button:hover {
  text-decoration: underline;
  color: #0056b3;
}
</style>

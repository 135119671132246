import { createStore } from 'vuex';
import redPacket from './modules/redPacket';
import auth from './modules/auth';
import profile from './modules/profile';
import app from './modules/app';

const store = createStore({
  modules: {
    redPacket,
    auth,
    profile,
    app,
  },
});

export default store;

<template>
  <div class="container">
    <div class="profile-avatar">
      <img :src="user.avatarUrl" alt="User Avatar" />
    </div>
    <el-form>
      <el-form-item>
        <label class="form-label">Full Name</label>
        <el-input v-model="user.full_name" placeholder="Full Name" disabled></el-input>
      </el-form-item>
      <el-form-item>
        <label class="form-label">Contact</label>
        <el-input v-model="user.phone_no" placeholder="Contact No." disabled></el-input>
      </el-form-item>
      <el-form-item>
        <label class="form-label">Email</label>
        <el-input v-model="user.email" placeholder="Email" disabled></el-input>
      </el-form-item>
      <el-form-item>
        <label class="form-label">Date of Birth</label>
        <el-input v-model="user.date_of_birth" placeholder="Date of Birth" disabled></el-input>
      </el-form-item>
      <el-form-item>
        <label class="form-label">Username</label>
        <el-input v-model="user.username" placeholder="Username" disabled></el-input>
      </el-form-item>
      <el-form-item>
        <label class="form-label">Referral Code</label>
        <el-input v-model="user.referral_code" placeholder="Referral Code" disabled></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('profile', {
      user: state => state.user,
    }),
  },
  created() {
    this.$store.dispatch('profile/fetchUserProfile');
  },
};
</script>

<style scoped>
.profile-avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

</style>

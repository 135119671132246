<template>
  <div class="top-nav">
    <div class="left-section">
      <img v-if="isHome" src="@/assets/joker.png" alt="Logo" class="logo" />
      <el-icon
        v-else
        style="font-size: 20px; color: #f23321;"
        @click="goBack"
      >
        <ArrowLeft />
      </el-icon>
    </div>
    <div class="center-section" v-if="!isHome">
      <span class="page-title">{{ pageTitle }}</span>
    </div>
    <div class="right-section">
      <el-icon
        v-if="isHome"
        style="font-size: 20px; color: #f23321;"
        @click="openSidebar"
      >
        <Expand />
      </el-icon>
      <el-icon
        v-else
        style="font-size: 20px; color: #f23321;"
        @click="openChat"
      >
        <Service />
      </el-icon>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.name === 'Home';
    },
    pageTitle() {
      return (this.$route.name || 'Page Title').toUpperCase();
    },
  },
  methods: {
    goBack() {
    this.$router.go(-1); // 返回上一页
  },
    openSidebar() {
      console.log('Sidebar opened');
    },
    openChat() {
      console.log('Chat opened');
    },
  },
};
</script>

<style scoped>
.top-nav {
  box-sizing: border-box; /* 添加这个属性 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 50px; /* 明确设置高度 */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}


.left-section,
.center-section,
.right-section {
  display: flex;
  align-items: center;
}

.left-section {
  flex: 1;
}

.center-section {
  flex: 2;
  justify-content: center;
}

.right-section {
  flex: 1;
  justify-content: flex-end;
}

.logo {
  height: 25px;
}

.page-title {
  font-size: 14px;
  color: #333;
}
</style>

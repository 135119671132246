<template>
    <div>
    <PromotionCard
      v-for="promotion in promotions"
      :key="promotion.id"
      :id="promotion.id.toString()"
      :imageSrc="promotion.image"
      :title="promotion.name"
      :description="promotion.description"
    />
  </div>
</template>

<script>
import PromotionCard from '@/views/Promotions/components/PromotionCard.vue';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    PromotionCard
  },
  computed: {
    ...mapState('app', ['promotions']),
  },
  mounted() {
    this.fetchPromotions();
  },
  methods: {
    ...mapActions('app', ['fetchPromotions']),
  }
}
</script>
